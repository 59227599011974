import { format } from "date-fns";
import { ElementRef } from "@angular/core";

import { combineLatest, Subject } from "rxjs";
import { HistoricoChatService } from "./../../services/historico-chat.service";
import { Component, OnInit, ViewChild } from "@angular/core";

import * as avatar from "../../utils/avatar";
import * as _ from "lodash";
import { UsuarioService } from "../../services/usuario.service";
import { take, map } from "rxjs/operators";
import { ChatService } from "src/app/services/chat.service";

declare var jQuery: any;

@Component({
  selector: "app-historico",
  templateUrl: "./historico.component.html",
  styleUrls: ["./historico.component.css"],
})
export class HistoricoComponent implements OnInit {
  private filtesr: Subject<string> = new Subject<string>();

  @ViewChild("isearchFirebase") searchF: ElementRef;
  @ViewChild("content") content: ElementRef;

  pesquisaFirebase: any;
  pesquisaInput: any;
  blockButton: boolean = true;
  chats: any;
  chat: any;
  months: any;
  conversa: any;
  userChat: any;
  gallery: any;

  constructor(
    private historicoChat: HistoricoChatService,
    private chatService: ChatService,
    private usuarioService: UsuarioService
  ) {}

  ngOnInit() {
    this.userChat = this.usuarioService.getUserChat();
  }

  blockButtonSearch() {
    if (String(this.pesquisaFirebase).length > 0) {
      return (this.blockButton = false);
    }
    return (this.blockButton = true);
  }
  searchChatCnpjRazao(value) {
    combineLatest(
      this.historicoChat.getChatsByCnpj(value.toUpperCase()),
      this.historicoChat.getChatsByRazaoSocial(value.toUpperCase())
    )
      .pipe(
        map((arr) => {
          return _.uniqBy(
            arr.reduce((acc, cur) => acc.concat(cur)),
            "id"
          );
        })
      )
      .pipe(take(1))
      .subscribe((result) => {
        this.chats = result.map((k: any) => {
          Object.keys(k.members).filter((key) => {
            k.members[key].role == "REVENDEDOR"
              ? (k.client = { ...k.members[key], id: key })
              : (k.clerk = { ...k.members[key], id: key });
          });
          return k;
        });
      });
  }

  searchChats(segmentName) {
    this.historicoChat.searchChatsBySegments(segmentName).subscribe((res) => {
      this.chats = res.map((k: any) => {
        Object.keys(k.members).filter((key) => {
          k.members[key].role == "REVENDEDOR"
            ? (k.client = { ...k.members[key], id: key })
            : (k.clerk = { ...k.members[key], id: key });
        });
        return k;
      });
    });

    this.cleanInputs();
  }

  searchChatsCof() {
    let list = ["COF", "COFU", "COFA", "TRR"];
    combineLatest(
      list.map((param) => {
        return this.historicoChat.searchChatsBySegments(param);
      })
    )
      .pipe(
        map((arr) => {
          return _.uniqBy(
            arr.reduce((acc, cur) => acc.concat(cur)),
            "id"
          );
        })
      )
      .pipe(take(1))
      .subscribe((result) => {
        this.chats = result.map((k: any) => {
          Object.keys(k.members).filter((key) => {
            if (k.members[key].role == "REVENDEDOR") {
              k.client = { ...k.members[key], id: key };
            }
            k.clerk = { ...k.members[key], id: key };
          });
          return k;
        });
      });
    this.cleanInputs();
  }

  loadStyleAvatar(name) {
    return avatar.createStyleAvatar(name);
  }

  loadAvatar(name) {
    return avatar.createAvatar(name);
  }

  cleanInputs() {
    this.pesquisaFirebase = "";
    this.pesquisaInput = "";
  }

  openChat(chat) {
    this.chat = "";
    this.chat = chat;

    this.historicoChat.getMessages(chat.id).subscribe((res) => {
      this.conversa = res.reverse();
      this.loadMonthsTime(res);
      this.scrollToBottom();
    });
    this.openImagesGallery(chat);
  }

  openImagesGallery(chat) {
    this.historicoChat.getImages(chat.id).subscribe((res) => {
      this.gallery = res;
    });
  }

  loadMonthsTime(value) {
    this.months = [];
    value.map((result) => {
      this.months.push(format(result.createdAt.toDate(), "MMM - YYYY"));
      this.months = _.uniq(this.months);
    });
  }

  getMessagesByMonth(date) {
    if (_.isEqual(date, "todos") || date === "todos")
      return this.openChat(this.chat);
    this.historicoChat
      .getMessagesByMonth(this.chat.id, date)
      .subscribe((result) => {
        this.conversa = result;
      });
  }

  private scrollToBottom(duration?: number) {
    setTimeout(() => {
      if (this.content) {
        this.content.nativeElement.scrollTop =
          this.content.nativeElement.scrollHeight;
      }
    }, 1500);
  }

  downloadImage(e, url) {
    this.chatService.downloadFile(url);
    e.preventDefault();
  }
}

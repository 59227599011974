import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class Headers {
  constructor() {}

  protected headersOpened: HttpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
}

const API_BASE = "https://servicos.ale.com.br/webserviceapp/api";
const API_BASE_STF = "https://app.ale.com.br/api";
const API_BASE_IBGE = "https://servicodados.ibge.gov.br/api/v1/localidades";

export const API_LOGIN = `${API_BASE}/login`;
export const API_VALIDATE_TOKEN = `${API_BASE}/validartoken`;
export const RECUPERARSENHA = `${API_BASE}/recuperarsenha`;
export const APIGRAPHQL = `${API_BASE_STF}/graphql`;
export const NOTIFICATIONS = `${API_BASE_STF}/notification`;
export const STATES = `${API_BASE_IBGE}/estados`;
export const CITIES = `${API_BASE_IBGE}/estados/`;

export const USER_ADMIN = "ADMIN";
export const USER_MARKET = "MARKET";
export const USER_FALE = "FALE";
export const USER_ASSESSOR = "CONSULTOR";
export const USER_REVENDEDOR = "REVENDEDOR";

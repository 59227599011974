export class UserChat {
  email: string;
  photoURL: string;
  displayName: string;
  createdAt: Date;
  authenticator: any;

  static USER_ANAL_RELAC: string = "FALE";
  static USER_REVENDEDOR: string = "REVENDEDOR";
  static USER_ASSESSOR: string = "CONSULTOR";

  constructor(
    name,
    email,
    photo,
    providerId,
    userId,
    userRole,
    assessorId = null
  ) {
    this.createdAt = new Date();
    this.displayName = name;
    this.email = email;
    this.photoURL = photo;
    this.authenticator = assessorId
      ? { providerId, userId, userRole, assessorId }
      : { providerId, userId, userRole };
  }
}

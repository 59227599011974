import {
  getBannersDesativados,
  getBannersFilters,
  getSegments,
  getDistributionBases,
} from "./../shared/querys";
import {
  addBanner,
  updateOrderBanner,
  disableBanner,
  updateUrlBanner,
} from "./../shared/mutations";
import { Apollo } from "apollo-angular";
import { Injectable } from "@angular/core";
import * as message from "../utils/mensagens";

@Injectable({
  providedIn: "root",
})
export class BannerService {
  constructor(private apollo: Apollo) {}

  /**
   * send banner
   * @param params - banner: image, url, order, group, activatedAt, deactivatedAt, filters(state, city, distribution base, cnpj)
   */
  sendBannerApi(params: any) {
    return this.apollo
      .mutate({
        mutation: addBanner,
        variables: {
          img: params.img,
          url: params.url,
          order: params.order,
          group: params.group,
          filters: params.filters,
          activatedAt: params.activatedAt,
          deactivatedAt: params.deactivatedAt,
        },
      })
      .toPromise()
      .then((success) => {
        message.aToastMenssageSuccess("Banner inserido com sucesso !");
        return success.data.createBanner;
      })
      .catch((err) => {
        message.aToastMenssageError("Ocorreu um erro ao inserir o banner !");
      });
  }

  /**
   * disable banner
   * @param params - banner id, current date
   */
  disableBanner(params: any) {
    return this.apollo
      .mutate({
        mutation: disableBanner,
        variables: {
          id: params.id,
          deactivatedAt: params.currentDate,
        },
      })
      .toPromise()
      .then((success) => {
        message.aToastMenssageSuccess("Banner removido com sucesso !");
        return success.data.updateBanner;
      })
      .catch((err) => {
        message.aToastMenssageError("Ocorreu um erro ao desativar o banner !");
      });
  }

  /**
   * load disabled banners
   * @param first - limit of returned banners
   * @param offset - index at the beginning of the list
   */
  loadBannerDesativados(first, offset) {
    return this.apollo
      .query({
        query: getBannersDesativados,
        variables: { first: first, offset: offset },
      })
      .toPromise()
      .then((result: any) => {
        this.apollo.getClient().cache.reset();
        return result.data.banners;
      });
  }

  /**
   * load banners by filters
   * @param params - segment id, filters(state, city, distribution base, cnpj)
   */
  loadBannerFilters(params: any) {
    return this.apollo
      .query({
        query: getBannersFilters,
        variables: {
          group: params.segmentId,
          filters: params.filters,
        },
      })
      .toPromise()
      .then((result: any) => {
        this.apollo.getClient().resetStore();
        return result.data.bannersByGroup;
      });
  }

  /**
   * update banner order
   * @param obj - banner object(id, image, url, status, order, group)
   */
  updateOrderBanner(obj) {
    return this.apollo
      .mutate({
        mutation: updateOrderBanner,
        variables: { id: obj.id, order: parseInt(obj.order) },
      })
      .toPromise()
      .then((success) => {
        message.aToastMenssage("success", "Banner alterado com sucesso!");
        return success.data.createBanner;
      })
      .catch((err) => {
        message.aToastMenssageError(
          "Ocorreu um erro ao alterar a ordem do banner !"
        );
      });
  }

  /**
   * update url banner
   * @param obj - banner object(id, image, url, status, order, group)
   */
  updateUrlBanner(obj) {
    return this.apollo
      .mutate({
        mutation: updateUrlBanner,
        variables: { id: obj.id, url: obj.url },
      })
      .toPromise()
      .then((success) => {
        message.aToastMenssage("success", "Banner alterado com sucesso!");
        return success.data.createBanner;
      })
      .catch((err) => {
        message.aToastMenssageError(
          "Ocorreu um erro ao alterar a url do banner !"
        );
      });
  }

  /**
   * get segments
   */
  getSegments() {
    return this.apollo
      .query({
        query: getSegments,
      })
      .toPromise()
      .then((result: any) => {
        this.apollo.getClient().cache.reset();
        return result.data.groupSegments;
      });
  }

  /**
   * get distribution bases
   */
  getDistributionBases() {
    return this.apollo
      .query({
        query: getDistributionBases,
      })
      .toPromise()
      .then((result: any) => {
        this.apollo.getClient().cache.reset();
        return result.data.distributionBases;
      });
  }
}

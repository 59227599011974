import { JwtModule } from "@auth0/angular-jwt";
import { AdminMarketGuard } from "./auth/admin-market.guard";
import { HttpClientModule } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "./app-routing.module";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "./components/common/common.module";
import { AppComponent } from "./app.component";
import { ComponentsModule } from "./components/components.module";
import { AuthGuard } from "./auth/auth.guard";
import { AuthService } from "./auth/auth.service";
import { GraphQLModule } from "./graphql.module";
import { SacAssessorGuard } from "./auth/sac-assessor.guard";
import { AngularFirestoreModule } from "angularfire2/firestore";
import { AngularFireModule } from "@angular/fire";
import { environment } from "../environments/environment.prod";
import { AngularFireStorageModule } from "angularfire2/storage";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    AngularFirestoreModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireStorageModule,
    CommonModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    GraphQLModule,
  ],
  providers: [AuthService, AuthGuard, SacAssessorGuard, AdminMarketGuard],
  bootstrap: [AppComponent],
})
export class AppModule {}

import {
  assertArgumentCPF,
  assertArgumentNotEmpty,
} from "../utils/assertionConcern";
import { Services } from "./../services/services";
import { UsuarioService } from "./../services/usuario.service";
import { Injectable, EventEmitter } from "@angular/core";
import { Users } from "./../models/users";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import * as _ from "lodash";
import * as message from "../utils/mensagens";
import * as url from "../../environments/consts";
import { UserChat } from "../models/userChat";

@Injectable({
  providedIn: "root",
})
export class AuthService extends Services {
  usuario: any;
  public changeUserSICOF = new EventEmitter<any>();

  constructor(
    private router: Router,
    private http: HttpClient,
    private userService: UsuarioService
  ) {
    super();
  }

  //metodo para realização do login
  login(usuario: Users) {
    return this.ValidationLogin(usuario)
      .then((_) => {
        return this.searchUsuario(usuario)
          .then(async (user: any) => {
            if (!user.PermissoesAcesso) {
              message.aMessagem("error", "Alerta !", "Usuário bloqueado", "");
              return Promise.resolve({ usuarioValido: false });
            }

            if (user.TipoUsuario == UserChat.USER_REVENDEDOR) {
              message.aMessagem(
                "error",
                "Alerta !",
                "Usuário não tem permissão para acessar esse sistema",
                ""
              );
              return Promise.resolve({ usuarioValido: false });
            }

            //if(user.SenhaTemporaria) { return Promise.resolve( { user, redirectChangePassword: true, usuarioValido: true })}

            this.setUser(user);
            await this.userService.initUserChat(user);

            return Promise.resolve({
              user,
              redirectChangePassword: false,
              usuarioValido: true,
            });
          })
          .catch((error) => {
            return Promise.reject(error.error.mensagem);
          });
      })
      .catch((error) => {
        //message.aMessagem('error', 'Error', `${error.error.mensagem}`, 'Entre em contato com o Fale.')
        return Promise.reject(error);
      });
  }

  //metódo para procurar o usuário na api
  searchUsuario(usuario) {
    return this.http
      .post(url.API_LOGIN, usuario, {
        headers: new HttpHeaders().set("Content-Type", "application/json "),
      })
      .toPromise()
      .then((user) => user)
      .catch((erro) => {
        return Promise.reject(erro);
      });
  }

  //método para saber se o usuário já está logado
  userIsLoggedIn() {
    console.log("LOGIN IN");
    if (this.getToken() == null)
      return Promise.resolve({ redirectChangePassword: true });
    //if(this.getUser().SenhaTemporaria ) return Promise.reject( { redirectChangePassword: true });

    return new Promise((resolve, reject) => {
      this.http
        .post(
          url.API_VALIDATE_TOKEN,
          { token: this.getToken() },
          { headers: this.headerOpened }
        )
        .toPromise()
        .then((res: any) => {
          if (res.valido) {
            return resolve({
              ...this.getUser(),
              redirectChangePassword: false,
            });
          }
          this.userRemoveLoggedIn();
          message.aMessagem(
            "warning",
            "Token Expirado!",
            "Sessão expirada, favor realizar login novamente.",
            "Você será redirecionado para o login."
          );
          resolve({ redirectChangePassword: true });
        })
        .catch((err) => {
          this.userRemoveLoggedIn();
          reject({ redirectChangePassword: true });
        });
    });
  }

  //método para colocar as informações do usuário no local storage
  private setUser(user: any) {
    delete user.Postos;
    delete user.mensagens;
    localStorage.setItem("USER_LOGED", JSON.stringify(user));
    localStorage.setItem("TOKEN", user.Token);
    this.changeUserSICOF.emit(user);
  }

  //método para recuperar usuário no local storage
  getUser() {
    return JSON.parse(localStorage.getItem("USER_LOGED"));
  }

  getToken() {
    return localStorage.getItem("TOKEN");
  }

  //método para remover o usuário logado
  userRemoveLoggedIn() {
    localStorage.removeItem("USER_LOGED");
    localStorage.removeItem("TOKEN");
    localStorage.removeItem("userChat");
  }

  ValidationLogin(credentials) {
    return new Promise((resolve, reject) => {
      const errorCPF = assertArgumentCPF(
        credentials.login,
        "Campo CPF Inválido."
      );
      const errorSenha = assertArgumentNotEmpty(
        credentials.senha,
        "Campo senha é obrigátorio."
      );

      if (errorCPF) {
        return reject(errorCPF);
      }

      if (errorSenha) {
        return reject(errorSenha);
      }

      return resolve();
    });
  }
}

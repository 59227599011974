import { DevicesByCNPJ } from "../models/notifications";
import {
  getDevicesByCnpj,
  getDevicesByGroup,
  getDevicesClienteByCnpj,
} from "../shared/querys";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as assertConcern from "../utils/assertionConcern";
import * as mensagens from "../utils/mensagens";
import * as _ from "lodash";
import { Apollo } from "apollo-angular";
import { NOTIFICATIONS } from "../../environments/consts";
import { Services } from "./services";

/**
 * Notification service with all the methods to create, build, format and send the notifications.
 */
@Injectable({
  providedIn: "root",
})
export class NotificationService extends Services {
  senders: Array<any> = [{}];
  constructor(private http: HttpClient, private apollo: Apollo) {
    super();
  }

  /**
   * Creates notifications, using a post method with all the necessary information.
   * @param notifications the notifications object containing an array of notifications to be sent to the users.
   */
  async createNotifications(notifications) {
    try {
      return this.http
        .post(NOTIFICATIONS, notifications, {
          headers: this.getNotificationHeaders(),
        })
        .toPromise()
        .then((data) => {
          mensagens.aMessagem(
            "success",
            "Notificação enviada!",
            "Dentro de poucos segundos os usuários deverão receber a notificação",
            ""
          );
          return true;
        })
        .catch((error) => {
          mensagens.aMessagem(
            "error",
            "Erro ao enviar a notificação",
            error,
            "error de servidor status: 500 "
          );
          return false;
        });
    } catch (error) {
      mensagens.aMessagem(
        "error",
        "Erro ao enviar a notificação",
        "Erro inesperado no envio da notificação",
        error.message
      );
      return false;
    }
  }
}

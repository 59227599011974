import {
  Component,
  Input,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { ChatService } from "../../../services/chat.service";
import { ActivatedRoute } from "@angular/router";
import { Subscription, Subject, Observable } from "rxjs";
import * as avatar from "../../../utils/avatar";
import { AuthService } from "src/app/auth/auth.service";
import { UsuarioService } from "src/app/services/usuario.service";
import { AngularFireUploadTask } from "angularfire2/storage";
import { Imagem } from "src/app/models/imagem";
import { finalize, map, take } from "rxjs/operators";
import Message, { MESSAGE_IMAGE, MESSAGE_TEXT } from "src/app/models/message";
import * as message from "../../../utils/mensagens";
import { resizer } from "../../../utils/imageHelper";
import * as _ from "lodash";
declare var jQuery: any;

@Component({
  selector: "conversa-component",
  templateUrl: "./conversa.component.html",
  styleUrls: ["./conversa.component.css"],
})
export class ConversaComponent implements OnInit, OnDestroy {
  @Input() userChat: any;
  @ViewChild("content") content: ElementRef;

  loading = new EventEmitter<boolean>();
  task: AngularFireUploadTask;
  uploadProgress: Observable<number>;
  downloadURL: Observable<string>;
  uploadState: Observable<string>;
  uploading: boolean = false;
  progress: any;
  messages = [];

  newMessage: Message = {};
  newImage: Imagem;

  userSICOF: any;

  contacts: any;
  chats: any;
  tabs: any = "conversas";
  channelId: any;
  channel: any;

  routeSubscription: Subscription;

  startAt = new Subject();
  endAt = new Subject();
  startobs = this.startAt.asObservable();
  endobs = this.endAt.asObservable();

  channelSubscription: Subscription;
  userSubscription: Subscription;
  messagesSubscription: Subscription;
  newMessagesSubscription: Subscription;
  offLineMessagesSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private chatService: ChatService,
    private usuarioService: UsuarioService,
    private route: ActivatedRoute
  ) {}

  //metodo para carregar o estilo do avatar, botões coloridos
  loadStyleAvatar(name) {
    return avatar.createStyleAvatar(name);
  }

  //método para carregar o avatar, gerar as iniciais
  loadAvatar(name) {
    return avatar.createAvatar(name);
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.channelId = params["channelId"];
      this.messages = [];
      this.userSICOF = this.authService.getUser();

      this.userChat = this.usuarioService.getUserChat();

      this.usuarioService.changeUserChat.subscribe((user) => {
        this.userChat = user;
      });

      this.converUnsubscriber();

      this.initMessages(this.channelId);

      this.channelSubscription = this.chatService
        .getChannelById(params["channelId"])
        .subscribe((channel) => {
          this.channel = channel;
          this.channel.member =
            this.channel.members[
              Object.keys(channel.members).find((key) => {
                if (key != this.userChat.id) return this.userChat;
              })
            ];

          if (
            this.channel.hasNewMessage &&
            this.channel.userIdLastMessage !== this.userChat.id
          ) {
            this.chatService.updateChannel(this.channel.id, {
              ...this.channel,
              hasNewMessage: false,
            });
          }
        });
    });
  }

  private initMessages(channelId: string) {
    this.messagesSubscription = this.chatService
      .getMessages(channelId)
      .pipe(take(1))
      .subscribe((messages) => {
        messages = messages.reverse();
        for (const message of messages) {
          this.TratarMensagem(message);
        }

        this.newMessagesSubscription = this.chatService
          .getMessages(channelId, true)
          .subscribe((messages) => {
            messages = messages.reverse();
            for (const message of messages) {
              this.TratarMensagem(message);
            }
            this.scrollToBottom();
          });

        this.offLineMessagesSubscription = this.chatService
          .getMessagesOffline(channelId)
          .subscribe((messages) => {
            messages = messages.reverse();
            for (const message of messages) {
              this.TratarMensagem(message);
            }
            this.scrollToBottom();
          });

        this.scrollToBottom();
        this.newMessage = this.chatService.newMessage;
      });
  }

  private TratarMensagem(message: Message) {
    const idx = this.messages.findIndex((item) => item.id == message.id);
    if (!message.visualized && message.from != this.userChat.id) {
      this.chatService.lerMessages(this.channel.id, message);
    }
    if (idx >= 0) {
      this.messages[idx] = message;
    } else {
      this.messages.push(message);
    }
  }

  groupByMessages(messages) {
    const groupedCollection = _.groupBy(messages, function (item) {
      var newDate = new Date(item.createdAt.seconds * 1000);
      return newDate.toLocaleDateString(undefined, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    });

    const newArray = Object.keys(groupedCollection).map((date) => ({
      date: date,
      messages: groupedCollection[date],
    }));
    return newArray;
  }

  upload(file) {
    if (file.type.split("/")[0] !== "image") {
      message.aToastMenssageError("Arquivo não suportado");
      return;
    }
    const ext = file.name.substr(file.name.lastIndexOf(".") + 1);

    const id = this.chatService.newId;

    const filename = `chat/${id}.${ext}`;
    this.task = this.chatService.uploadImage(file, filename, file.name);
    this.uploadProgress = this.task.percentageChanges();
    this.uploadState = this.task.snapshotChanges().pipe(map((s) => s.state));
    this.uploadProgress.subscribe((progress) => {
      this.progress = Math.round(progress);
      this.uploading = true;
    });
    this.task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadURL = this.chatService.downloadImage(filename);
          this.downloadURL.subscribe((url) => {
            this.uploading = false;
            const userId = this.userChat.id;
            const channelId = this.channel.id;

            this.newMessage.id = id;
            this.newMessage.channelId = channelId;
            this.newMessage.from = userId;

            this.newMessage.type = MESSAGE_IMAGE;
            this.messages.push(this.newMessage);
            this.scrollToBottom();

            this.chatService
              .sendMessage({ ...this.newMessage, data: url })
              .then((_) => {
                this.newImage = new Imagem(channelId, userId, url);
                this.newImage.id = id;
                this.chatService.sendImage(this.newImage);
                this.newMessage = this.chatService.newMessage;
              });
          });
        })
      )
      .subscribe();
  }

  enviarMensagem() {
    if (!this.newMessage.data || !this.newMessage.data.trim()) {
      return true;
    }
    const id = this.chatService.newId;
    const userId = this.userChat.id;
    const channelId = this.channel.id;

    this.newMessage.id = id;
    this.newMessage.channelId = channelId;
    this.newMessage.from = userId;
    this.newMessage.type = MESSAGE_TEXT;
    this.messages.push(this.newMessage);
    this.scrollToBottom();

    this.chatService.sendMessage(this.newMessage);
    this.newMessage = this.chatService.newMessage;
  }

  private scrollToBottom(duration?: number) {
    setTimeout(() => {
      if (this.content) {
        this.content.nativeElement.scrollTop =
          this.content.nativeElement.scrollHeight;
      }
    }, 1500);
  }

  selectPhoto(event) {
    const file = event.target.files[0];
    event.target.value = "";
    if (file.type.split("/")[0] !== "image") {
      message.aToastMenssageError("Arquivo não suportado");
      return;
    }

    resizer(file).then((newFile) => {
      this.upload(newFile);
    });
  }

  ngOnDestroy(): void {
    this.converUnsubscriber();
  }

  downloadImage(e, url) {
    this.chatService.downloadFile(url);
    e.preventDefault();
  }

  converUnsubscriber() {
    if (this.channelSubscription) this.channelSubscription.unsubscribe();

    if (this.userSubscription) this.userSubscription.unsubscribe();

    if (this.messagesSubscription) this.messagesSubscription.unsubscribe();

    if (this.newMessagesSubscription)
      this.newMessagesSubscription.unsubscribe();

    if (this.offLineMessagesSubscription)
      this.offLineMessagesSubscription.unsubscribe();
  }
}

import { Router } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-nova-senha",
  templateUrl: "./nova-senha.component.html",
  styleUrls: ["./nova-senha.component.css"],
})
export class NovaSenhaComponent implements OnInit {
  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {}

  sair() {
    this.authService.userRemoveLoggedIn();
    this.router.navigate(["/login"]);
  }
}

import { format } from "date-fns";
import Message from "./../models/message";
import { switchMap } from "rxjs/operators";
import { FirebaseService, AFSQuery } from "./firebase.service";
import { AngularFireStorage } from "angularfire2/storage";
import { AngularFirestore } from "angularfire2/firestore";
import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import * as _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class HistoricoChatService extends FirebaseService {
  lastTime = new BehaviorSubject(new Date());

  constructor(afs: AngularFirestore, afstorage: AngularFireStorage) {
    super(afs, afstorage);
  }

  searchChatsBySegments(segmentName) {
    const query = new AFSQuery();
    query.where = [["segment", "==", _.lowerCase(segmentName)]];
    return this.collection("chats", query);
  }

  getChatsByCnpj(value) {
    const query = new AFSQuery();
    query.where = [["cnpj", "==", value]];
    return this.collectionPromise("chats", query);
  }

  getChatsByRazaoSocial(value) {
    const query = new AFSQuery();
    query.where = [["razaoSocial", "==", _.lowerCase(value)]];
    return this.collectionPromise("chats", query);
  }

  getMessages(channelId: string, isNewOnly = false) {
    const query = new AFSQuery();
    query.limit = 50;
    query.orderBy = ["createdAt", "desc"];
    if (!isNewOnly) {
      return this.getMessagesByChannel(channelId, query);
    }

    return this.lastTime.pipe(
      switchMap((datetime) => {
        query.where = [["createdAt", ">", datetime]];
        return this.getMessagesByChannel(channelId, query);
      })
    );
  }

  getImages(channelId: string) {
    const query = new AFSQuery();
    return this.collection<Message>(`chats/${channelId}/images`, query);
  }

  private getMessagesByChannel(channelId: string, query: AFSQuery) {
    return this.collection<Message>(`chats/${channelId}/messages`, query);
  }

  getMessagesByMonth(chatId, month) {
    let date = new Date(month);

    let start = date.setMonth(date.getMonth(), 1);
    let end = date.setMonth(date.getMonth(), 30);
    //console.log(dateEnd)
    const query = new AFSQuery();
    query.where = [
      ["createdAt", ">", new Date(start)],
      ["createdAt", "<", new Date(end)],
    ];

    return this.collection(`chats/${chatId}/messages`, query);
  }
}

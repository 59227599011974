import { Pipe, PipeTransform } from "@angular/core";
import { format } from "date-fns";
import * as pt from "date-fns/locale/pt";

@Pipe({
  name: "timeAgo",
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: any): string {
    return format(value, "D [de] MMMM [de] YYYY, H:mm A", { locale: pt });
  }
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as url from "../../environments/consts";

@Injectable({
  providedIn: "root",
})
export class LocationService {
  constructor(private http: HttpClient) {}

  /**
   * get states
   */
  getStates(): Promise<any> {
    return this.http.get<any>(url.STATES).toPromise();
  }

  /**
   * get cities by state
   * @param uf - state id
   */
  getCitiesByState(uf: any): Promise<any> {
    return this.http.get<any>(`${url.CITIES}${uf}/municipios`).toPromise();
  }
}

import { Injectable } from "@angular/core";
import { RECUPERARSENHA } from "./../../environments/consts";
import { HttpClient } from "@angular/common/http";
import {
  assertArgumentCPF,
  assertArgumentDataNascimento,
} from "../utils/assertionConcern";
import { Headers } from "../utils/headers";

@Injectable({
  providedIn: "root",
})
export class EsqueceuSenhaService extends Headers {
  constructor(private http: HttpClient) {
    super();
  }

  Validate(obj?: any) {
    return new Promise((resolve, reject) => {
      const errorCPF = assertArgumentCPF(obj.cpf, "Campo CPF Inválido.");
      const errorDataNascimento = assertArgumentDataNascimento(
        obj.dataNascimento.replace(/[^\d]/g, ""),
        "Data de nascimento inválida."
      );

      if (errorCPF) {
        return reject(errorCPF);
      }
      if (errorDataNascimento) {
        return reject(errorDataNascimento);
      }
      return resolve();
    });
  }

  POST(data?: any) {
    return this.http
      .post(RECUPERARSENHA, data, { headers: this.headersOpened })
      .toPromise()
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}

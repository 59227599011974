import { Component, OnInit, Input, ElementRef } from "@angular/core";

@Component({
  selector: "app-menu-tree",
  templateUrl: "./menu-tree.component.html",
  styleUrls: ["./menu-tree.component.css"],
})
export class MenuTreeComponent implements OnInit {
  @Input() menu: Array<any>;

  constructor(private _elementRef: ElementRef) {}

  ngAfterViewInit() {
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "../assets/js/misc.js";
    this._elementRef.nativeElement.appendChild(s);
  }
  ngOnInit() {}
}

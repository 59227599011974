import {
  getRegiao,
  getCompanyPreferencial,
  getClientByCnpj,
} from "./../../../shared/querys";
import { Apollo } from "apollo-angular";
import { Component, OnInit, ElementRef, Input, ViewChild } from "@angular/core";
import { NotificationService } from "../../../services/notification.service";
import * as _ from "lodash";
import * as mensagens from "../../../utils/mensagens";
import * as assertions from "../../../utils/assertionConcern";
import * as dateTime from "../../../utils/date-time";

@Component({
  selector: "app-notificacao-texto",
  templateUrl: "./notificacao-texto.component.html",
  styleUrls: ["./notificacao-texto.component.css"],
})
export class NotificacaoTextoComponent implements OnInit {
  /**
   * Avaiable sets got emoji appearance.
   */
  sets = [
    "native",
    "google",
    "twitter",
    "facebook",
    "emojione",
    "apple",
    "messenger",
  ];
  set = "apple";
  showEmojiPicker = false;

  businessRegion;
  preferredBranch;
  segmentsCheckbox: any;
  inputCNPJ;
  region;
  branch;
  clients = [];

  //DateTime fields on screen for programming notification
  initialDate: any;
  initialTime: any;
  dateTimeMin: any;

  @Input() notification = { type: "push", title: "", text: "", url: "" };
  @ViewChild("regionSelector") regionSelector: ElementRef;
  @ViewChild("branchSelector") branchSelector: ElementRef;
  @ViewChild("pushRadio") pushRadio: ElementRef;

  constructor(
    private notificationService: NotificationService,
    private _elementRef: ElementRef,
    private apollo: Apollo
  ) {}

  ngAfterViewInit() {
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "../assets/js/misc.js";
    this._elementRef.nativeElement.appendChild(s);
  }

  async ngOnInit() {
    await this.loadRegion();
    await this.loadPreferredBranch();
    this.checkboxs();

    this.initializeDateTime();
  }

  /**
   * Initialize date variables.
   */
  initializeDateTime() {
    this.initialDate = dateTime.initializeDate();
    this.initialTime = dateTime.initializeTime();
    this.dateTimeMin = dateTime.initializeDate();
  }

  /**
   * Loads the business regions.
   */
  loadRegion() {
    this.apollo
      .query<any>({
        query: getRegiao,
      })
      .toPromise()
      .then(({ data }) => {
        this.businessRegion = data.businessRegions;
      });

    console.log("Business region: ", this.businessRegion);
  }

  /**
   * Loads preferred branches.
   */
  loadPreferredBranch() {
    this.apollo
      .query<any>({
        query: getCompanyPreferencial,
      })
      .toPromise()
      .then(({ data }) => {
        console.log("Preferred branch: : ", data.PreferendCompanies);
        this.preferredBranch = data.PreferendCompanies;
      });
  }

  /**
   * Get client by cnpj.
   */
  getClient(cnpj) {
    return this.apollo
      .query<any>({
        query: getClientByCnpj,
        variables: { cnpj },
      })
      .toPromise()
      .then((result: any) => {
        console.log("Result: " + result);
        return result.data.clientByCNPJ;
      });
  }

  /**
   * Checks if cnpj is already included.
   * @param inputCnpj the cnpj input in the screen
   * @return true if it is already included, toasting a message to informar the user, or false if is not included.
   */
  isCnpjAlreadyIncluded(inputCnpj) {
    const cnpjIncluded = this.clients.find(
      (client) => client.cnpj == inputCnpj
    );

    if (!_.isNil(cnpjIncluded)) {
      mensagens.aToastMenssageError(`Client com CNPJ ${inputCnpj} já incluso`);
      return true;
    }
    return false;
  }

  /**
   * Searchs for the client related to the input cnpj.
   * @param inputCnpj the input cnpj
   */
  async searchClientByCnpj(inputCnpj) {
    if (!this.isCnpjAlreadyIncluded(inputCnpj)) {
      let client = await this.getClient(inputCnpj);
      if (_.isNil(client)) {
        mensagens.aToastMenssageError(
          `Client com cnpj ${inputCnpj} não encontrado.`
        );
      } else {
        this.clients.push(client);
      }
    }
    this.inputCNPJ = "";
  }

  /**
   * Radio change for notifications type choosen by the user.
   * @param obj the object
   * @param event the change event
   */
  onRadioChange(obj: any, event: any) {
    this.notification.type = event.target.value;
  }

  /**
   * Method for segments checkbox change choosen by the user.
   * @param obj the object
   * @param event the change event
   */
  onCheckboxChange(obj: any, event: any) {
    if (event.target.checked) {
      if (obj.nome == "chktodos") {
        for (var i = 0; i < this.segmentsCheckbox.length; i++) {
          this.segmentsCheckbox[i].checked = true;
        }
      } else {
        this.segmentsCheckbox.checked = true;
      }
    } else {
      for (var i = 0; i < this.segmentsCheckbox.length; i++) {
        if (obj.nome == "chktodos") {
          this.segmentsCheckbox[i].checked = false;
        }
        if (this.segmentsCheckbox[i] == obj.id) {
          this.segmentsCheckbox[i].checked = false;
        }
      }
    }
    console.log(this.segmentsCheckbox);
  }

  async onSubmit() {
    mensagens
      .aConfirmacao(
        "Você tem realmente certeza que deseja enviar uma Notificação para esses usuários ? "
      )
      .then(async (result) => {
        if (assertions.assertArgumentTrue(result.value, "") == undefined) {
          let date = dateTime.convertDateTime(
            this.initialDate,
            this.initialTime
          );
          var segments: any = await this.segmentsChecked(this.segmentsCheckbox);

          if (await this.validateNotification(segments, this.notification)) {
            let cnpjs = this.clients.map((client) => client.cnpj);

            let region = _.isNil(this.region)
              ? undefined
              : parseInt(this.region);
            let branch = _.isNil(this.branch)
              ? undefined
              : parseInt(this.branch);
            let target = this.buildTarget(cnpjs, segments, region, branch);
            var notification = this.buildSingleNotification(
              this.notification.type,
              date,
              target,
              this.notification
            );
            console.log("notification: ", notification);

            this.notificationService
              .createNotifications(notification)
              .then((result) => {
                if (result) this.cleanFields();
              });
          }
        } else {
          mensagens.aMessagem(
            "error",
            "Notificação cancelada.",
            "Sua requisição de envio de solicitação foi cancelada com sucesso !",
            ""
          );
        }
      });
  }

  /**
   * Builds the target object with a specific format.
   * @param cnpjs the cnpjs for which the notification will be sent
   * @param groups the groups for which the notification will be sent
   * @param region the region for which the notification will be sent
   * @param company the company for which the notification will be sent
   */
  buildTarget(cnpjs, groups, region, company) {
    return { cnpjs, groups, region, company };
  }

  /**
   * Builds the notification object with a specific format.
   * @param type the notification type e.g.: Push
   * @param date the date and time for when the notification will be sent
   * @param target the target for the notification
   * @param notificationData the notification data contain the title, text and url
   */
  buildSingleNotification(type, date, target, notificationData) {
    let { title, text, url } = notificationData;

    return {
      notifications: [
        {
          type,
          date,
          target,
          message: {
            title,
            text,
            url,
          },
        },
      ],
    };
  }

  /**
   * Returns a object containing all the checked segments.
   * @param segmentsChecked
   */
  segmentsChecked(segmentsChecked) {
    const segments = [];
    if (segmentsChecked[0].checked == true) {
      segments.push("REDE", "SPOT", "COF");
    } else {
      for (const item of segmentsChecked) {
        if (item.checked) segments.push(item.title);
      }
    }
    return segments;
  }

  /**
   * Validate notification's mandatory fields.
   * @param segments the segments for which the notification will be send
   * @param notification the notification data
   * @return false if an error was found, toasting a message to inform the user, or true if no error was found.
   */
  validateNotification(segments, notification) {
    var errors = [];

    errors.push(assertions.assertArgumentNotNull(segments, "Segmentos"));
    errors.push(assertions.assertArgumentNotEmpty(notification.type, " Tipo"));
    errors.push(
      assertions.assertArgumentNotEmpty(notification.title, " Título")
    );
    errors.push(
      assertions.assertArgumentNotEmpty(notification.text, " Mensagem")
    );

    errors = errors.filter((error) => error !== undefined);

    if (errors.length > 0) {
      mensagens.aMessagem(
        "error",
        "Erro ao enviar a notificação",
        `Os campos ${errors} não podem estar vazios !`,
        "preencha todos os campos obrigatórios marcados com *"
      );
      return false;
    }
    return true;
  }

  /**
   * Gets region.
   * @param obj
   */
  getRegion(obj) {
    this.region = obj;
  }

  /**
   * Gets branch.
   * @param obj
   */
  getBranch(obj) {
    this.branch = obj;
  }

  /**
   * Initialize the checkbox values.
   */
  checkboxs() {
    this.segmentsCheckbox = [
      { title: "Todos", nome: "chktodos", value: "0", checked: false },
      { title: "Rede", nome: "chkrede", value: "1", checked: false },
      { title: "Spot", nome: "chkspot", value: "2", checked: false },
      { title: "Cof", nome: "chkcof", value: "3", checked: false },
    ];
  }

  /**
   * Clean the form fields.
   */
  cleanFields() {
    this.regionSelector.nativeElement.value = "0";
    this.branchSelector.nativeElement.value = "0";
    this.pushRadio.nativeElement.checked = true;
    this.clients = [];
    this.notification = { type: "push", title: "", text: "", url: "" };
    for (const check of this.segmentsCheckbox) {
      check.checked = false;
    }
  }

  /**
   * Removes the client from the target list.
   * @param client
   */
  removeClient(client) {
    console.log("Client to be removed: ", client);
    _.remove(this.clients, client);
    console.log("Remaining clients: ", this.clients);
  }

  /**
   * Change showEmojiPicker if button is clicked.
   */
  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  /**
   * Adds an emoji to the current message, based on the event.
   * @param event the event
   */
  addEmoji(event) {
    const text = `${this.notification.text}${event.emoji.native}`;
    this.notification.text = text;
  }

  /**
   * If focus on the message, showEmojiPicker screen is closed.
   */
  onFocus() {
    this.showEmojiPicker = false;
  }
}

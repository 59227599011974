export class Users {
  IdUsuario: string;
  NomeUsuario: string;
  TipoUsuario: string;
  Email: string;
  login: string;
  senha: string;
  Token: string;
  permissoesAcesso: string;
  constructor() {
    this.login = "";
    this.senha = "";
  }
}

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyCU8vO-x6nf9G9JopOr7gOtJG_1FlYgYkg",
    authDomain: "app-ale-pedidos.firebaseapp.com",
    databaseURL: "https://app-ale-pedidos.firebaseio.com",
    projectId: "app-ale-pedidos",
    storageBucket: "app-ale-pedidos.appspot.com",
    messagingSenderId: "789013779607",
  },
};

import { Router } from "@angular/router";
import { Users } from "./../../../models/users";
import { AuthService } from "./../../../auth/auth.service";
import { Component, OnInit } from "@angular/core";
import * as avatar from "../../../utils/avatar";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  user: Users;
  iniciaisNome = "";

  constructor(private authService: AuthService, private router: Router) {
    this.user = this.authService.getUser();
  }

  ngOnInit() {}

  loadStyleAvatar(nome) {
    return avatar.createStyleAvatar(nome);
  }

  loadAvatar(nome) {
    return avatar.createAvatar(nome);
  }

  logout() {
    this.authService.userRemoveLoggedIn();
    return this.router.navigate(["/login"]);
  }
}

import { DocumentNode } from "graphql";
import gql from "graphql-tag";

export const addBanner: DocumentNode = gql`
  mutation (
    $img: String!
    $url: String
    $order: Int!
    $group: Int!
    $filters: BannerFiltersCreateInput
    $activatedAt: Date!
    $deactivatedAt: Date
  ) {
    createBanner(
      input: {
        img: $img
        url: $url
        order: $order
        group: $group
        filters: $filters
        activatedAt: $activatedAt
        deactivatedAt: $deactivatedAt
      }
    ) {
      id
      img
      url
      order
      group {
        id
      }
      filters {
        locationState
        locationCity
        cnpj
        distributionBase {
          code
        }
      }
      activatedAt
      deactivatedAt
    }
  }
`;

export const disableBanner: DocumentNode = gql`
  mutation updateBanner($id: ID!, $deactivatedAt: Date) {
    updateBanner(id: $id, input: { deactivatedAt: $deactivatedAt }) {
      id
      group {
        id
      }
      img
      order
      url
      activatedAt
      deactivatedAt
    }
  }
`;

export const updateOrderBanner: DocumentNode = gql`
  mutation updateBanner($id: ID!, $order: Int) {
    updateBanner(id: $id, input: { order: $order }) {
      id
      order
    }
  }
`;

export const updateUrlBanner: DocumentNode = gql`
  mutation updateBanner($id: ID!, $url: String) {
    updateBanner(id: $id, input: { url: $url }) {
      id
      url
    }
  }
`;

import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { Subject, combineLatest, Subscription } from "rxjs";
import { map, debounceTime, take } from "rxjs/operators";
import { ChatService } from "../../../services/chat.service";
import * as _ from "lodash";
import * as avatar from "../../../utils/avatar";
import { Router } from "@angular/router";
import { Chat } from "src/app/models/chat";

@Component({
  selector: "channel-list",
  templateUrl: "./channel-list.component.html",
  styleUrls: ["./channel-list.component.css"],
})
export class ChannelListComponent implements OnInit, OnDestroy {
  ngOnDestroy(): void {
    if (this.chatInitSubscription) this.chatInitSubscription.unsubscribe();

    if (this.chatsSubscription) this.chatsSubscription.unsubscribe();

    if (this.filterSubscription) this.filterSubscription.unsubscribe();

    if (this.filterSubscription) this.filterSubscription.unsubscribe();
  }

  @Input() userChat: any;

  chats: any = [];
  term: string;

  chatsSubscription: Subscription;
  chatInitSubscription: Subscription;
  filterSubscription: Subscription;
  combinerLastSubscription: Subscription;

  startAt = new Subject();
  endAt = new Subject();
  private filter: Subject<string> = new Subject<string>();
  startobs = this.startAt.asObservable();
  endobs = this.endAt.asObservable();

  constructor(private chatService: ChatService, private router: Router) {}

  ngOnInit() {
    this.filterSubscription = this.filter
      .asObservable()
      .pipe(debounceTime(300))
      .subscribe((data) => {
        let value = !data ? "" : data;
        value = this.myCapitalize(value);
        this.startAt.next(value);
        this.endAt.next(value + "\uf8ff");
        if (this.combinerLastSubscription) {
          this.combinerLastSubscription.unsubscribe();
        }

        this.combinerLastSubscription = combineLatest(
          this.startobs,
          this.endobs
        )
          .pipe(take(1))
          .subscribe((value) => {
            if (this.chatsSubscription) {
              this.chatsSubscription.unsubscribe();
            }

            this.chatsSubscription = this.chatService
              .OgetChannelsByUserId(value[0], value[1], this.userChat.id)
              .pipe(
                map((channels) =>
                  channels.map((c: any) => {
                    c.memberId = Object.keys(c.members).find(
                      (key) => key != this.userChat.id
                    );
                    return c;
                  })
                )
              )
              .subscribe((result) => {
                if (this.chatInitSubscription) {
                  this.chatInitSubscription.unsubscribe;
                }
                this.chats = result;
              });
          });
      });

    this.handleFilterChange("");
    this.loadData();
  }

  loadData() {
    this.chatInitSubscription = this.chatService
      .OgetChannelsByUserId("", "", this.userChat.id)
      .subscribe((result) => {
        for (const chat of result) {
          this.tratarChat(chat);
        }
      });
  }

  tratarChat(chat) {
    const idx = this.chats.findIndex((item) => item.id === chat.id);
    if (idx >= 0) {
      this.chats[idx] = chat;
    } else {
      this.chats.push(chat);
    }
  }

  handleFilterChange(value: string) {
    this.filter.next(value);
  }

  myCapitalize(arg) {
    let result = _.map(arg.split(" "), _.upperFirst).join(" ");
    return result.trim();
  }

  //metodo para carregar o estilo do avatar, botões coloridos
  loadStyleAvatar(name) {
    return avatar.createStyleAvatar(name);
  }

  //método para carregar o avatar, gerar as iniciais
  loadAvatar(name) {
    return avatar.createAvatar(name);
  }

  goToChannel(channel) {
    this.router.navigate(["/chat"], { queryParams: { channelId: channel.id } });
  }
}

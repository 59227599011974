import { FirebaseService, AFSQuery } from "./firebase.service";
import { Injectable, EventEmitter } from "@angular/core";
import { AngularFirestore } from "angularfire2/firestore";
import { UserChat } from "../models/userChat";
import * as _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class UsuarioService extends FirebaseService {
  constructor(afs: AngularFirestore) {
    super(afs);
  }

  public changeUserChat: EventEmitter<any> = new EventEmitter();

  initUserChat(user) {
    switch (user.TipoUsuario) {
      case UserChat.USER_ANAL_RELAC:
        this.createNewUser(user).then((userChat) => {
          this.setUserChat(userChat);
        });
        break;
      case UserChat.USER_ASSESSOR:
        this.createNewUser(user).then((userChat) => {
          this.setUserChat(userChat);
        });
        break;
      default:
        break;
    }
  }

  //método para retornar o usuario registrado no firebase de acordo com sua id
  getUserByAuthenticatorUserId(userId: string, userTipo: string) {
    const query = new AFSQuery();
    query.limit = 1;
    query.where = [
      ["authenticator.userId", "==", userId],
      ["authenticator.userRole", "==", userTipo],
    ];

    return this.collectionPromise(`users`, query);
  }

  //método para retornar um usuário por id
  getUserById(userId: string) {
    return this.docPromise(`users/${userId}`);
  }

  //método para criar um novo usuário no firebase, caso não esteje regitrado.
  createNewUser(user: any) {
    return new Promise((resolve, reject) => {
      return this.getUserByAuthenticatorUserId(
        user.IdUsuario,
        user.TipoUsuario
      ).then((oldUser) => {
        if (oldUser.length > 0) {
          return resolve(oldUser[0]);
        }

        const newUser = new UserChat(
          user.NomeUsuario,
          user.Email,
          user.photo || "",
          "SICOF",
          user.IdUsuario,
          user.TipoUsuario,
          user.IdComicionado
        );
        const id = this.generatedId;
        this.set(`users`, id, {
          ...newUser,
          id,
          nameInsensitive: _.lowerCase(newUser.displayName),
        }).then((_) => {
          this.getUserById(id).then((user) => {
            resolve(user);
          });
        });
      });
    });
  }

  setUserChat(user) {
    localStorage.setItem("userChat", JSON.stringify(user));
    this.changeUserChat.emit(user);
  }

  getUserChat() {
    return JSON.parse(localStorage.getItem("userChat"));
  }
}

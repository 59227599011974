import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import * as message from "../../utils/mensagens";

import { Banner } from "./types";
import { BannerService } from "../../services/banner.service";
import { LocationService } from "../../services/location.service";
import * as _ from "lodash";
import * as assertions from "../../utils/assertionConcern";
import * as dateTime from "../../utils/date-time";
declare var jQuery: any;

@Component({
  selector: "app-banner",
  templateUrl: "./banner.component.html",
  styleUrls: ["./banner.component.css"],
})
export class BannerComponent implements OnInit {
  @ViewChild("order") order: ElementRef;
  @ViewChild("url") url: ElementRef;

  banners: Observable<Banner[]>;

  constructor(
    private apollo: Apollo,
    private bannerService: BannerService,
    private elementRef: ElementRef,
    private locationService: LocationService
  ) {}

  segmentos: any[] = [];
  bannersAtivos: any[] = [];
  bannersRealizados: any[] = [];
  bannerVazio = {
    id: "",
    img: "assets/images/img-placeholder.jpg",
    group: "",
    order: 1,
    url: "",
  };
  erro = "";
  tipoArquivo = "";
  tamanhoArquivo = null;
  stateList: any = [];
  cityList: any = [];
  distributionBaseList: any = [];
  state: any = "";
  city: any = "";
  distributionBase: any = "";
  cnpj: any = "";
  keywordState = "nome";
  keywordCity = "nome";
  keywordDistributionBase = "description";
  notFoundState = "Estado não encontrado.";
  notFoundCity = "Cidade não encontrada.";
  notFoundDistributionBase = "Base de Distribuição não encontrada.";
  showCnpjAlert: boolean = false;
  dateTimeMin: any;
  initialDate: any;
  initialTime: any;
  finalDate: any;
  finalTime: any;

  showProgramBanner: boolean = false;
  /**
   * This method is called when the checkbox program banner is changed:
   * checked: sets the current date in the fields initialDate/initialTime and setting a minimum date.
   * not checked: clean date and time fields.
   */
  onChangeProgramBanner() {
    if (this.showProgramBanner) {
      this.initialDate = dateTime.initializeDate();

      this.initialTime = dateTime.initializeTime();

      this.dateTimeMin = dateTime.initializeDate();
    } else {
      this.clearDateTimeFields();
    }
  }

  /**
   * This method clean date and time fields.
   */
  clearDateTimeFields() {
    this.initialDate = undefined;
    this.initialTime = undefined;
    this.finalDate = undefined;
    this.finalTime = undefined;
  }

  disableSend: boolean = false;

  /**
   * This method is called when the date and time fields are changed,
   * disabling send button if the end date is before the start date.
   */
  onChangeDateTime(e) {
    let inicial = dateTime.convertDateTime(this.initialDate, this.initialTime);
    let final = dateTime.convertDateTime(this.finalDate, this.finalTime);
    if (final && inicial >= final) this.disableSend = true;
    else this.disableSend = false;
  }

  loadingSegments: boolean = true;
  /**
   * This method obtains segment list by request and controls the loading of filters.
   */
  async initSegments() {
    this.loadingSegments = true;
    await this.bannerService.getSegments().then(async (segments?: any) => {
      this.segmentos = segments;
      this.loadingSegments = false;
    });
  }

  loadingStateList: boolean = true;
  /**
   * This method obtains state list by request and controls the loading of state autocomplete.
   */
  getStateList() {
    this.loadingStateList = true;
    this.locationService
      .getStates()
      .then((states) => {
        states.sort((a, b) => {
          if (a.nome < b.nome) return -1;
          if (a.nome > b.nome) return 1;
          return 0;
        });
        states.unshift({ nome: "Todos" });
        this.stateList = states;
        this.loadingStateList = false;
      })
      .catch((error) => {
        this.loadingStateList = false;
      });
  }

  loadingCityList: boolean = false;
  /**
   * This method obtains city list by state id per request and controls the loading of city autocomplete.
   * @param id - state id
   */
  getCityList(id: any) {
    this.loadingCityList = true;
    this.locationService
      .getCitiesByState(id)
      .then((municipios) => {
        municipios.sort((a, b) => {
          if (a.nome < b.nome) return -1;
          if (a.nome > b.nome) return 1;
          return 0;
        });
        municipios.unshift({ nome: "Todas" });
        this.cityList = municipios;
        this.loadingCityList = false;
      })
      .catch((error) => {
        this.loadingCityList = false;
      });
  }

  loadingDistributionBaseList: boolean = true;
  /**
   * This method obtains distribution base list by request and controls the loading of distribution base autocomplete.
   */
  getDistributionBasesList = () => {
    this.loadingDistributionBaseList = true;
    this.bannerService
      .getDistributionBases()
      .then((distributionBase: any) => {
        distributionBase.sort((a, b) => {
          if (a.description < b.description) return -1;
          if (a.description > b.description) return 1;
          return 0;
        });
        distributionBase.unshift({ description: "Todas" });
        this.distributionBaseList = distributionBase;
        this.loadingDistributionBaseList = false;
      })
      .catch((err) => {
        this.loadingDistributionBaseList = false;
      });
  };

  loadingSendBanner: boolean = false;
  /**
   * This method send the banner.
   * @param obj - banner object(image, url, order, group)
   */
  enviaBanner(obj) {
    this.loadingSendBanner = true;
    obj.group = this.selectedSegmento.id;
    var errors = this.validateEnvioBanner(obj);
    if (errors.length > 0) {
      this.loadingSendBanner = false;
      return message.aMessagem(
        "warning",
        "Atenção",
        errors,
        "preencha todos os campos com ( * )"
      );
    }

    let params = {
      img: obj.img,
      url: obj.url,
      order: obj.order,
      group: obj.group,
      filters: {
        locationState: this.state.sigla ? this.state.sigla : null,
        locationCity: this.city.nome
          ? this.city.nome != "Todas"
            ? this.city.nome
            : null
          : null,
        distributionBase: this.distributionBase.code
          ? this.distributionBase.code
          : null,
        cnpj: this.cnpjValidation(this.cnpj) ? this.cnpj : null,
      },
      activatedAt: dateTime.convertDateTime(this.initialDate, this.initialTime)
        ? dateTime.convertDateTime(this.initialDate, this.initialTime)
        : new Date(),
      deactivatedAt: dateTime.convertDateTime(this.finalDate, this.finalTime),
    };

    this.bannerService
      .sendBannerApi(params)
      .then((result: any) => {
        this.limparCampos();
        this.loadBanner();
        this.loadingSendBanner = false;
      })
      .catch((err) => {
        this.loadingSendBanner = false;
      });
  }

  /**
   * This method disabled banner.
   * @param obj - banner object(id, image, url, order, group)
   */
  desativaBanner(obj) {
    let currentDate = new Date();
    let params = {
      id: obj.id,
      currentDate: new Date(currentDate.getTime() - 60 * 1000),
    };
    this.bannerService.disableBanner(params).then(({ data }) => {
      this.loadBanner();
    });
  }

  /**
   * This method update banner order.
   * @param order - banner order
   * @param bannerId - banner id
   */
  onChangeInputOrder(order, bannerId) {
    if (order) {
      let obj = { id: bannerId, order: order };
      this.bannerService.updateOrderBanner(obj).then((result) => {});
    }
  }

  /**
   * This method update banner url.
   * @param url - banner url
   * @param bannerId  - banner id
   */
  onChangeInputUrl(url, bannerId) {
    let obj = { id: bannerId, url: url };
    this.bannerService.updateUrlBanner(obj).then((result) => {});
  }

  /**
   * Loads the image thumbnail, sets the object path, handles upload errors.
   * @param event - event
   * @param obj - banner object(id, image, url, order, group)
   */
  onFileSelected(event, obj) {
    let input = event.target; // captura todo o campo INPUT com a imagem inserida
    try {
      this.tipoArquivo = event.target.files[0].type;
      this.tamanhoArquivo = parseInt(event.target.files[0].size);
    } catch (error) {
      this.erro = "no-file";
    }

    if (this.tipoArquivo != "image/jpeg" && this.tipoArquivo != "image/png") {
      this.erro = "erro-img";
    } else if (this.tamanhoArquivo > 500000) {
      //500000 = 500k
      this.erro = "erro-size";
    } else if (this.erro == "no-file") {
      this.erro = "obrigatorio";
    } else {
      this.erro = "";
      let reader = new FileReader();
      try {
        reader.readAsDataURL(input.files[0]); // lendo a imagem do input carregado acima
      } catch (error) {
        //Nenhuma imagem selecionada
      }
      reader.onload = () => {
        let dataURL = reader.result;
        obj.img = "";
        obj.img = dataURL.toString();
        let output = document.getElementById("out" + input.id);
        input.value = "";
      };
    }
  }

  selectedSegmento: any;
  loadingBanner: boolean = true;
  /**
   * This method obtains banner list by filters.
   */
  loadBanner() {
    if (this.selectedSegmento) {
      this.loadingBanner = true;

      let params = {
        segmentId: this.selectedSegmento.id,
        filters: {
          locationState: this.state.sigla ? this.state.sigla : null,
          locationCity: this.city.nome
            ? this.city.nome != "Todas"
              ? this.city.nome
              : null
            : null,
          distributionBases: this.distributionBase.code
            ? this.distributionBase.code
            : null,
          cnpj: this.cnpjValidation(this.cnpj) ? this.cnpj : null,
          exact: true,
        },
      };

      this.loadBannerFilters(params).then((banners: Array<any>) => {
        banners.map((banner) => {
          banner["initialDateTime"] = banner.activatedAt
            ? new Date(banner.activatedAt)
            : "-";
          banner["finallDateTime"] = banner.deactivatedAt
            ? new Date(banner.deactivatedAt)
            : "-";
        });
        this.bannersAtivos = banners;
        this.loadingBanner = false;
      });
    }
  }

  /**
   * This method obtains banner list by filters per request.
   * @param params - segment id, filters(state, city, distribution base, cnpj)
   */
  loadBannerFilters(params: any) {
    return this.bannerService
      .loadBannerFilters(params)
      .then((result: Array<any>) => {
        return result;
      })
      .catch((err) => {});
  }

  loadingBannerRealizados: boolean = true;
  /**
   * This method obtains disabled banners.
   * @param first - limit of returned banners
   * @param offset - index at the beginning of the list
   */
  loadBannerRealizados(first, offset) {
    this.loadingBannerRealizados = true;
    /* message.aWaitMessagePreConfirm('Carregando', 'Galeria de banners !', ); */
    this.bannerService
      .loadBannerDesativados(first, offset)
      .then((data: any) => {
        this.bannersRealizados = data;
        message.aToastMenssage("success", "Galeria de banners carregado!");
        this.loadingBannerRealizados = false;
      })
      .catch((err) => {
        this.loadingBannerRealizados = false;
      });
  }

  /**
   * This method clears fields after sending the banner.
   */
  limparCampos() {
    this.bannerVazio = {
      id: "",
      img: "assets/images/img-placeholder.jpg",
      group: "",
      order: 1,
      url: "",
    };
    this.clearDateTimeFields();
    this.showProgramBanner = false;
  }

  /**
   * This method validate banner fields.
   * @param obj - banner object(id, image, url, order, group)
   */
  validateEnvioBanner(obj) {
    var errors = [];
    errors.push(
      assertions.assertArgumentEquals(
        obj.img,
        "assets/images/img-placeholder.jpg",
        "por favor insira uma imagem"
      )
    );
    errors.push(
      assertions.assertArgumentNotEmpty(obj.order, "por favor insira uma ordem")
    );
    return (errors = errors.filter((x) => x !== undefined));
  }

  /**
   * This method refresh disabled banners.
   */
  refreshBannersRealizados() {
    this.loadBannerRealizados(10, 0);
  }

  /**
   * This method is called when the state is selected.
   * @param item - item state(id, nome, regiao, sigla)
   */
  selectState(item) {
    this.cityList = [];
    if (item != "Todos" && item.nome != "Todos") {
      this.getCityList(item.id);
    }
    this.state = item;
    this.loadBanner();
  }

  /**
   * This method is called when the city is selected.
   * @param item - item city(id, nome, microrregiao)
   */
  selectCity(item) {
    this.city = item;
    this.loadBanner();
  }

  /**
   * This method is called when the distribution base is selected.
   * @param item - item distribution base(code, company, cnpj, description, city, state)
   */
  selectDistributionBase(item) {
    this.distributionBase = item;
    this.loadBanner();
  }

  /**
   * This method is called when the state is changed.
   */
  onChangeState() {
    this.cityList = [];
  }

  /**
   * This method is called when the cnpj gains focus.
   * @param item asd
   */
  onFocusedState(item) {
    this.cityList = [];
  }

  /**
   * This method is called when the cnpj loses focus.
   * @param e - cnpj
   */
  focusOutCnpj(e) {
    if (this.cnpj) {
      if (this.cnpjValidation(this.cnpj)) {
        this.showCnpjAlert = false;
        this.loadBanner();
      } else this.showCnpjAlert = true;
    } else this.showCnpjAlert = false;
  }

  /**
   * Ignores pasted texts.
   * @param event - event
   */
  copyPasted(event) {
    event.preventDefault();
  }

  /**
   * In the order field, accepts only 1, 2, 3 and backspace.
   * @param event - event
   */
  onKeypress(event: any) {
    if (![8, 49, 50, 51].includes(event.keyCode)) {
      event.preventDefault();
    }
  }

  /**
   * ngOnInit
   */
  ngOnInit() {
    this.initSegments();
    this.getStateList();
    this.getDistributionBasesList();
    this.loadBannerRealizados(10, 0);
    //Função para a galeria funcionar
    // (function ($) {
    //   'use strict';
    //   if ($("#lightgallery").length) {
    //     $("#lightgallery").lightGallery();
    //   }
    //   if ($("#lightgallery-without-thumb").length) {
    //     $("#lightgallery-without-thumb").lightGallery({
    //       thumbnail: true,
    //       animateThumb: false,
    //       showThumbByDefault: false
    //     });
    //   }
    //   if ($("#video-gallery").length) {
    //     $("#video-gallery").lightGallery();
    //   }
    // });
  }

  /**
   * cnpj validation - referece https://www.geradorcnpj.com/javascript-validar-cnpj.htm
   * @param cnpj - cnpj
   */
  cnpjValidation(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, "");

    if (cnpj == "") return false;

    if (cnpj.length != 14) return false;

    if (
      cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999"
    )
      return false;

    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) return false;

    return true;
  }
}

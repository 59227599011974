import { AuthService } from "./auth.service";
import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanLoad,
  Route,
} from "@angular/router";
import { Observable } from "rxjs";
import * as message from "../utils/mensagens";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private authService: AuthService, private router: Router) {}
  timer: any = undefined;

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.setResetInterval(true);
    return this.verificaAcesso();
  }

  canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {
    return this.verificaAcesso();
  }

  async verificaAcesso() {
    return this.authService.userIsLoggedIn().then((user?: any) => {
      if (user.redirectChangePassword) {
        this.router.navigate(["/login"]);
        this.setResetInterval(false);
        return false;
      }

      return true;
    });
  }

  //implementação de melhoria para espiração do token automaticamente.
  setResetInterval(bool) {
    if (bool) {
      if (!this.timer) {
        this.timer = setInterval(() => {
          this.verificaAcesso();
        }, 60000);
      }
    } else {
      clearInterval(this.timer);
      this.timer = undefined;
    }
  }
}

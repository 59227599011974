import {
  AngularFireStorage,
  AngularFireUploadTask,
} from "angularfire2/storage";
import { Imagem } from "../../models/imagem";
import Message from "../../models/message";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { map, take, debounceTime } from "rxjs/operators";
import { Component, OnInit, Output } from "@angular/core";
import { ElementRef, ViewChild, EventEmitter } from "@angular/core";
import { Subject, combineLatest, fromEvent } from "rxjs";
import { ChatService } from "./../../services/chat.service";
import { AuthService } from "./../../auth/auth.service";
import * as avatar from "../../utils/avatar";
import * as _ from "lodash";
import { UsuarioService } from "../../services/usuario.service";
import { Router } from "@angular/router";

declare var jQuery: any;

@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.css"],
})
export class ChatComponent implements OnInit {
  @ViewChild("searchContacts") search: ElementRef;
  @ViewChild("isearchChat") searchchats: ElementRef;

  @Output() onChangeChat = new EventEmitter<any>();
  //teste
  loading = new EventEmitter<boolean>();
  task: AngularFireUploadTask;
  uploadProgress: Observable<number>;
  downloadURL: Observable<string>;
  uploadState: Observable<string>;
  uploading: boolean = false;

  messages = [];

  newMessage: Message;
  newImage: Imagem;
  gallery: any;
  user: any;
  userChat: any;
  contacts: any;
  chats: any;
  tabs: any = "conversas";
  channelId: any;

  private filter: Subject<string> = new Subject<string>();

  startAt = new Subject();
  endAt = new Subject();
  startobs = this.startAt.asObservable();
  endobs = this.endAt.asObservable();

  constructor(
    private authService: AuthService,
    private usuarioService: UsuarioService,
    private route: ActivatedRoute,
    private chatService: ChatService
  ) {}

  ngOnInit() {
    this.user = this.authService.getUser();
    this.userChat = this.usuarioService.getUserChat();

    this.route.queryParams.subscribe((params) => {
      this.channelId = params.channelId;
      this.openImagesGallery(params.channelId);
    });

    this.usuarioService.changeUserChat.subscribe((user) => {
      this.userChat = user;
    });
  }

  onChangeTab(tab) {
    this.tabs = tab;
  }

  openImagesGallery(channelId) {
    this.chatService.getImages(channelId).subscribe((res) => {
      this.gallery = res;
    });
  }

  downloadImage(e, url) {
    this.chatService.downloadFile(url);
    e.preventDefault();
  }
}

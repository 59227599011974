import { Component, OnInit, Input } from "@angular/core";
import { Subject, combineLatest } from "rxjs";
import { map, debounceTime, take } from "rxjs/operators";
import { ChatService } from "../../../services/chat.service";
import * as _ from "lodash";
import * as avatar from "../../../utils/avatar";
import { Router } from "@angular/router";

@Component({
  selector: "contato-list",
  templateUrl: "./contato-list.component.html",
  styleUrls: ["./contato-list.component.css"],
})
export class ContatoListComponent implements OnInit {
  @Input() userChat: any;

  contacts: any;
  term: string;

  startAt = new Subject();
  endAt = new Subject();
  private filter: Subject<string> = new Subject<string>();

  startobs = this.startAt.asObservable();
  endobs = this.endAt.asObservable();

  constructor(private chatService: ChatService, private router: Router) {}

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.chatService
      .getContactsUsersByAssessorId(this.userChat.authenticator.assessorId)
      .subscribe((result) => {
        this.contacts = result
          .map((contato: any) => {
            return contato.authenticator.postos[
              this.userChat.authenticator.assessorId
            ]
              .filter((posto) => posto.segment === "POS")
              .map((posto) => {
                return { ...contato, posto };
              });
          })
          .reduce((acc, cur) => {
            return acc.concat(cur);
          });
      });
  }

  myCapitalize(arg) {
    let result = _.map(arg.split(" "), _.upperFirst).join(" ");
    return result.trim();
  }

  //metodo para carregar o estilo do avatar, botões coloridos
  loadStyleAvatar(name) {
    return avatar.createStyleAvatar(name);
  }

  //método para carregar o avatar, gerar as iniciais
  loadAvatar(name) {
    return avatar.createAvatar(name);
  }

  goToChannel(contact) {
    const wonner = this.userChat;
    const posto = contact.posto;
    const member = contact;

    this.chatService
      .createDirectChannelByAssessor(wonner, posto, member)
      .then((channelId) => {
        this.router.navigate(["/chat"], { queryParams: { channelId } });
      });
  }
}

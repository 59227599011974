export class Imagem {
  from: string;
  createAt: any;
  updateAt: any;
  data: string;
  set: boolean;
  channelId: string;
  id?: string;
  url?: string;

  constructor(channelId, from, data) {
    this.data = data;
    this.from = from;
    this.channelId = channelId;
    this.set = false;
  }
}

import { Pipe, PipeTransform } from "@angular/core";
import { UsuarioService } from "../services/usuario.service";
import { of } from "rxjs";

@Pipe({
  name: "userProfile",
})
export class UserProfilePipe implements PipeTransform {
  constructor(private userService: UsuarioService) {}

  transform(userId: string) {
    return userId ? this.userService.getUserById(userId) : of(null);
  }
}

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyBmEXt5RRfkpXpSobjorJc7nWKj-cHEvk8",
    authDomain: "chat-teste-9fbfd.firebaseapp.com",
    databaseURL: "https://chat-teste-9fbfd.firebaseio.com",
    projectId: "chat-teste-9fbfd",
    storageBucket: "chat-teste-9fbfd.appspot.com",
    messagingSenderId: "734106212996",
  },
};

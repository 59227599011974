import { switchMap } from "rxjs/operators";
import { Router } from "@angular/router";
import { EsqueceuSenhaService } from "./../../services/esqueceu-senha.service";
import { Component, OnInit, ElementRef } from "@angular/core";
import * as mensagem from "../../utils/mensagens";
import * as _ from "lodash";

@Component({
  selector: "app-esqueci-senha",
  templateUrl: "./esqueci-senha.component.html",
  styleUrls: ["./esqueci-senha.component.css"],
})
export class EsqueciSenhaComponent implements OnInit {
  obj: any = { cpf: "", dataNascimento: "", sms: "true" };

  constructor(
    private _elementRef: ElementRef,
    private esqueceuSenhaService: EsqueceuSenhaService,
    private router: Router
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "../../../assets/js/misc.js";

    this._elementRef.nativeElement.appendChild(s);
  }

  novaSenha() {
    this.esqueceuSenhaService
      .Validate(this.obj)
      .then((res) => {
        let envio = {
          cpf: this.obj.cpf,
          dataNascimento:
            this.obj.dataNascimento.substr(4, 7).toString() +
            this.obj.dataNascimento.substr(2, 2).toString() +
            this.obj.dataNascimento.substr(0, 2).toString(),
          sms: this.obj.sms,
        };

        this.esqueceuSenhaService
          .POST(envio)
          .then((res?: any) => {
            mensagem.aMessagem("success", "Senha Enviada", res.mensagem, "");
            this.router.navigate(["/login"]);
          })
          .catch((err) => {
            mensagem.aMessagem(
              "error",
              "Erro",
              err.error.mensagem,
              "dados informados estão incorretos"
            );
          });
      })
      .catch((err) => {
        mensagem.aMessagem(
          "error",
          "Erro",
          err,
          "preencha os campos para proceguir"
        );
      });
  }

  voltar() {
    this.router.navigate(["/login"]);
  }
}

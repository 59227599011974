import { DocumentNode } from "graphql";
import gql from "graphql-tag";

//BANNERS
export const getBannersQuery: DocumentNode = gql`
  query banners {
    banners {
      id
    }
  }
`;

//NOTIFICATION
export const getDevices: DocumentNode = gql`
  query {
    devices {
      id
      token
      platform
      clients {
        cnpj
      }
    }
  }
`;

export const getDevicesByCnpj: DocumentNode = gql`
  query ($cnpj: String!) {
    deviceByCNPJ(cnpj: $cnpj) {
      token
      platform
      clients {
        cnpj
      }
    }
  }
`;

export const getDevicesByGroup: DocumentNode = gql`
  query ($group: [String!]!, $company: Int, $region: Int) {
    deviceByGroup(groups: $group, company: $company, region: $region) {
      token
      platform
      clients {
        name
        cnpj
        group
      }
    }
  }
`;

export const getRegiao: DocumentNode = gql`
  query {
    businessRegions {
      id
      name
    }
  }
`;

export const getCompanyPreferencial: DocumentNode = gql`
  query {
    PreferendCompanies {
      id
      name
    }
  }
`;

export const getDevicesClienteByCnpj: DocumentNode = gql`
  query ($cnpj: String!) {
    deviceByCNPJ(cnpj: $cnpj) {
      token
      platform
      clients {
        cnpj
        group
        name
        region {
          name
        }
      }
    }
  }
`;

export const getBannersDesativados: DocumentNode = gql`
  query ($first: Int, $offset: Int) {
    banners(first: $first, offset: $offset) {
      id
      img
      group {
        id
        name
      }
      order
      url
    }
  }
`;

export const getBannersFilters: DocumentNode = gql`
  query ($group: Int!, $filters: BannerFiltersSearchInput) {
    bannersByGroup(group: $group, filters: $filters) {
      id
      img
      group {
        id
        name
      }
      order
      url
      activatedAt
      deactivatedAt
    }
  }
`;

export const getSegments: DocumentNode = gql`
  query {
    groupSegments(first: 0) {
      id
      name
    }
  }
`;

export const getDistributionBases: DocumentNode = gql`
  query {
    distributionBases(first: 0) {
      code
      company
      description
      city
      state
      cnpj
    }
  }
`;

export const getClientByCnpj: DocumentNode = gql`
  query ($cnpj: String!) {
    clientByCNPJ(cnpj: $cnpj) {
      id
      group
      cnpj
      name
      region {
        id
        name
      }
    }
  }
`;

import { Directive, HostListener, ElementRef, OnInit } from "@angular/core";

@Directive({ selector: "[InputTextOnly]" })
export class InputTextOnlyDirective implements OnInit {
  private el: HTMLInputElement;

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.el.value = this.el.value.replace(/[^\d]/g, "");
  }
  @HostListener("keypress", ["$event.target.value"])
  onkeypress(value) {
    if (!value) {
      return value;
    }
    const onlyNums = value.replace(/[^\d]/g, "");
    this.el.value = onlyNums;
  }

  @HostListener("keydown", ["$event.target.value"])
  onkeydown(value) {
    if (!value) {
      return value;
    }
    const onlyNums = value.replace(/[^\d]/g, "");
    this.el.value = onlyNums;
  }

  @HostListener("keyup", ["$event.target.value"])
  onkeyup(value) {
    if (!value) {
      return value;
    }
    const onlyNums = value.replace(/[^\d]/g, "");
    this.el.value = onlyNums;
  }

  @HostListener("blur", ["$event.target.value"])
  onblur(value) {
    if (!value) {
      return value;
    }
    const onlyNums = value.replace(/[^\d]/g, "");
    this.el.value = onlyNums;
  }
}

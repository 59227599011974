import { AuthService } from "src/app/auth/auth.service";
import { Component, OnInit } from "@angular/core";
import { ViewEncapsulation } from "@angular/core";
import { any } from "async";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit {
  segmento: any;
  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.segmento = this.authService.getUser();
  }
}

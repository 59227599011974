/**
 * Date time helper class to deal with date procedures.
 */

/**
 * Initialize Date and returns a string with a specific date format.
 * @return string with the date format
 */
const initializeDate = () => {
  let dateTime = new Date();
  return (
    dateTime.getFullYear() +
    "-" +
    ("0" + (dateTime.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + dateTime.getDate()).slice(-2)
  );
};

/**
 * Initialize Time and returns a string with a specific time format.
 * @return string with the date format
 */
const initializeTime = () => {
  let dateTime = new Date();
  return (
    ("0" + dateTime.getHours()).slice(-2) +
    ":" +
    ("0" + dateTime.getMinutes()).slice(-2)
  );
};

/**
 * Converts a date object to a specific date and time format.
 * @param date the date object to be formatted
 * @return string with the date and time format
 */
const convertDateToString = (date) => {
  return (
    ("0" + date.getDate()).slice(-2) +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    date.getFullYear() +
    " " +
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2)
  );
};

/**
 * This method converts a date and time string to type date and if it is not possible it returns null.
 * @param dateString date string
 * @param timeString time string
 * @return string with the date format or null if it was not possible to format it
 */
const convertDateTime = (dateString, timeString) => {
  if (dateString) {
    let date = dateString.split("-");
    if (timeString) {
      let time = timeString.split(":");
      return new Date(date[0], date[1] - 1, date[2], time[0], time[1]);
    } else {
      return new Date(date[0], date[1] - 1, date[2]);
    }
  } else {
    return null;
  }
};

export { initializeDate, initializeTime, convertDateTime, convertDateToString };

import { HttpHeaders } from "@angular/common/http";
import { AuthService } from "../auth/auth.service";

export class Services {
  constructor() {}

  protected headers: Headers = new Headers({
    "Content-Type": "application/json",
  });
  protected headerOpened: HttpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });

  protected headerClosed(token) {
    var validateToken: HttpHeaders = new HttpHeaders({
      Authorization: String(token),
    });

    return validateToken;
  }

  protected getNotificationHeaders(): HttpHeaders {
    const v = {} as any;
    const { CPF, Token } = new AuthService(v, v, v).getUser();
    return new HttpHeaders({
      ["x-app-token"]: Token || "",
      cpf: CPF || "",
      mobile: "true",
    });
  }
}

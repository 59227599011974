import { FormsModule } from "@angular/forms";

import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { HeaderComponent } from "./header/header.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { FooterComponent } from "./footer/footer.component";
import { ContainerComponent } from "./container/container.component";
import { MenuComponent } from "./menu/menu.component";
import { MenuTreeComponent } from "./menu-tree/menu-tree.component";
import { BrowserModule } from "@angular/platform-browser";
import { SiteLayoutComponent } from "./layouts/site-layout/site-layout.component";
@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    ContainerComponent,
    MenuComponent,
    MenuTreeComponent,
    SiteLayoutComponent,
  ],

  imports: [BrowserModule, RouterModule, FormsModule],
  exports: [
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    ContainerComponent,
    MenuComponent,
    MenuTreeComponent,
    SiteLayoutComponent,
  ],
})
export class CommonModule {}

import { Users } from "./../../../models/users";
import { AuthService } from "./../../../auth/auth.service";
import { Component, OnInit, ElementRef } from "@angular/core";
import * as CONSTS from "../../../../environments/consts";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.css"],
})
export class MenuComponent implements OnInit {
  private user: Users;
  public menu = [];
  constructor(
    private _elementRef: ElementRef,
    private authService: AuthService
  ) {}
  showFirebase: boolean;

  ngOnInit() {
    if (this.authService.userIsLoggedIn()) {
      this.user = this.authService.getUser();

      let tipoUsuario = this.user.TipoUsuario;
      this.showFirebase =
        tipoUsuario === CONSTS.USER_ADMIN || tipoUsuario === CONSTS.USER_MARKET;
    }

    this.menuMount.map((res, index) => {
      this.menuMount[index].segmento.map((res) => {
        if (res === this.user.TipoUsuario)
          this.menu.push(this.menuMount[index]);
      });
    });
  }

  ngAfterViewInit() {
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "../assets/js/misc.js";
    this._elementRef.nativeElement.appendChild(s);
  }

  menuMount = [
    {
      menuTitle: "Home",
      segmento: [
        CONSTS.USER_ADMIN,
        CONSTS.USER_MARKET,
        CONSTS.USER_FALE,
        CONSTS.USER_ASSESSOR,
      ],
      icon: "menu-icon mdi mdi-television",
      routerActive: "active",
      collapse: "",
      routerlink: "/dashboard",
    },
    {
      menuTitle: "Chat",
      segmento: [CONSTS.USER_FALE, CONSTS.USER_ASSESSOR],
      icon: "menu-icon mdi mdi-message-text-outline",
      routerActive: "active",
      collapse: "",
      routerlink: "/chat",
    },
    {
      menuTitle: "Notificações",
      segmento: [CONSTS.USER_ADMIN, CONSTS.USER_MARKET],
      icon: "menu-icon mdi mdi-bell-outline",
      routerActive: "active",
      collapse: "menu-arrow",
      routerlink: null,
      menuTree: [
        {
          menuTitle: "Alerta e push - filtros",
          menuLink: "/notificacao-texto",
          routerActive: "active",
        },
        {
          menuTitle: "Alerta e push - planilha",
          menuLink: "/notificacao-xls",
          routerActive: "active",
        },
      ],
    },
    {
      menuTitle: "Banners",
      segmento: [CONSTS.USER_ADMIN, CONSTS.USER_MARKET],
      icon: "menu-icon mdi mdi-message-image",
      routerActive: "active",
      collapse: "",
      routerlink: "/banner",
    },
    {
      menuTitle: "Historico Chat",
      segmento: [CONSTS.USER_ADMIN, CONSTS.USER_MARKET],
      icon: "menu-icon mdi mdi-history",
      routerActive: "active",
      collapse: "",
      routerlink: "/historico",
    },
  ];

  listaMenu = [
    { menuTitle: "Alerta e push - filtros", menuLink: "notificacao-texto" },
    { menuTitle: "Alerta e push - planilha", menuLink: "notificacao-xls" },
  ];
}

import { Component, OnInit, Input } from "@angular/core";
import { format, differenceInDays } from "date-fns";
import * as pt from "date-fns/locale/pt";

@Component({
  selector: "group-date-chat",
  templateUrl: "./group-date-chat.component.html",
  styleUrls: ["./group-date-chat.component.css"],
})
export class GroupDateChatComponent implements OnInit {
  @Input() dateCurrent: any;
  @Input() dateAfter: any;
  @Input() index: any;
  date: any;
  constructor() {}

  ngOnInit() {
    let dif;
    if (this.index > 0) {
      dif = differenceInDays(
        new Date(format(this.dateCurrent, "MMM D, YYYY")),
        new Date(format(this.dateAfter, "MMM D, YYYY"))
      );
    } else {
      dif = 1;
    }

    return dif >= 1
      ? (this.date = format(this.dateCurrent, "D [de] MMMM [de] YYYY", {
          locale: pt,
        }))
      : (this.date = null);
  }
}

const createAvatar = (displaynome = "Usuário Desconhecido") => {
  var arrayName = displaynome.split(" ");
  var nome = arrayName[0][0];
  var sobrenome = arrayName[arrayName.length - 1][0];
  var iniciais = `${nome}${sobrenome}`.toUpperCase();
  return iniciais;
};

const createStyleAvatar = (nome) => {
  var sigla = createAvatar(nome);
  var colours = [
    "#1abc9c",
    "#2ecc71",
    "#3498db",
    "#9b59b6",
    "#34495e",
    "#16a085",
    "#27ae60",
    "#2980b9",
    "#8e44ad",
    "#2c3e50",
    "#f1c40f",
    "#e67e22",
    "#e74c3c",
    "#95a5a6",
    "#f39c12",
    "#d35400",
    "#c0392b",
    "#bdc3c7",
    "#7f8c8d",
  ];
  var charIndex = sigla.charCodeAt(0) - 65,
    colourIndex = charIndex % 19;

  return colours[colourIndex];
};

export { createAvatar, createStyleAvatar };

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filterContatosSearch",
})
export class FilterContatosPipe implements PipeTransform {
  constructor() {}

  transform(arr: any, term: any) {
    if (term === undefined) return arr;

    return arr.filter((res) => {
      const fc = String(res.posto.cnpj).toLocaleLowerCase();
      const fc1 = String(res.posto.razao).toUpperCase();
      const fc2 = String(res.nameInsensitive).toLowerCase();
      const fc3 = String(res.displayName).toLowerCase();
      return (
        fc.includes(term.toLowerCase()) ||
        fc1.includes(term.toUpperCase()) ||
        fc2.includes(term.toLowerCase()) ||
        fc3.includes(term.toLowerCase())
      );
    });
  }
}

import { FirebaseService, AFSQuery } from "./firebase.service";
import { Injectable } from "@angular/core";
import { AngularFirestore } from "angularfire2/firestore";
import { AngularFireStorage } from "angularfire2/storage";
import * as _ from "lodash";
import { combineLatest } from "rxjs";
import { Imagem } from "../models/imagem";
import { Chat } from "../models/chat";
import { BehaviorSubject } from "rxjs";
import { switchMap, map, take } from "rxjs/operators";
import Message from "../models/message";

@Injectable({
  providedIn: "root",
})
export class ChatService extends FirebaseService {
  lastTime = new BehaviorSubject(new Date());

  constructor(afs: AngularFirestore, afstorage: AngularFireStorage) {
    super(afs, afstorage);
  }

  get newMessage() {
    const message: Message = {};
    message.id = this.generatedId;
    message.data = "";

    return message;
  }

  getMessages(channelId: string, isNewOnly = false) {
    const query = new AFSQuery();
    query.limit = 50;
    if (!isNewOnly) {
      query.orderBy = ["createdAt", "desc"];
      return this.getMessagesByChannel(channelId, query);
    }

    return this.lastTime.pipe(
      switchMap((datetime) => {
        query.where = [["createdAt", ">", datetime]];
        return this.getMessagesByChannel(channelId, query);
      })
    );
  }

  getMessagesOffline(channelId: string) {
    const query = new AFSQuery();
    query.orderBy = ["createdAt", "desc"];
    query.where = [["visualized", "==", false]];
    return this.getMessagesByChannel(channelId, query);
  }

  private getMessagesByChannel(channelId: string, query: AFSQuery) {
    return this.collection<Message>(`chats/${channelId}/messages`, query);
  }

  //método para retornar os usuários do assessor assim que ele entrar no chat
  getContactsUsersByAssessorId(assessorId: string) {
    const query = new AFSQuery();

    query.where = [["authenticator.assessors", "array-contains", assessorId]];

    return this.collection("users", query);
  }

  //método para buscar contatos do assessor assim que ele comessar a digitar um nome no input de pesquisar
  getContactBySearch(start, end, assessorId) {
    const query = new AFSQuery();
    query.startAt = _.lowerCase(start);
    query.endAt = _.lowerCase(end);
    query.orderBy = ["nameInsensitive", "asc"];

    query.where = [["authenticator.assessors", "array-contains", assessorId]];

    return this.collection("users", query);
  }

  getChannelById(channelId: string) {
    return this.doc<Chat>(`chats/${channelId}`);
  }

  createDirectChannel(owner: any, user: any) {
    const query = new AFSQuery();

    query.where = [
      [`members.${owner.id}`, "==", true],
      [`members.${user.id}`, "==", true],
    ];

    return new Promise((resolve) => {
      this.collection<Chat>("chats", query)
        .pipe(take(1))
        .subscribe((channels) => {
          if (channels.length) {
            resolve(channels[0].id);
          } else {
            const members = {};
            members[owner.id] = true;
            members[user.id] = true;

            const newId = this.generatedId;
            const timestamp = this.timestamp;

            this.set("chats", newId, {
              id: newId,
              type: "direct",
              members: members,
              owner: owner.id,
              purpose: `created by ${owner.displayName}`,
              createdAt: timestamp,
              updatedAt: timestamp,
            }).then((_) => resolve(newId));
          }
        });
    });
  }

  sendMessage(message: Message) {
    const timestamp = this.timestamp;

    return Promise.all([
      this.set(`chats/${message.channelId}/messages`, message.id, {
        from: message.from,
        data: message.data,
        createdAt: timestamp,
        type: message.type,
        set: true,
        visualized: false,
      }),
      this.updateChannel(message.channelId, {
        lastMessage: `${message.type == "IMAGE" ? "Foto" : message.data}`,
        userIdLastMessage: message.from,
        updatedAt: timestamp,
        hasNewMessage: true,
      }),
    ]);
  }

  updateChannel(channelId: string, data: any) {
    this.update("chats", channelId, data);
  }

  updateMessage(channelId: string, message: Message) {
    this.update(`chats/${channelId}/messages`, message.id, message);
  }

  lerMessages(channelId: string, message: Message) {
    this.updateMessage(channelId, { ...message, visualized: true });
  }

  get newId() {
    return this.generatedId;
  }

  sendImage(image: Imagem) {
    const timestamp = this.timestamp;
    return Promise.all([
      this.set(`chats/${image.channelId}/images`, image.id, {
        from: image.from,
        data: image.data,
        createdAt: timestamp,
      }),
      this.updateChannel(image.channelId, {
        lastMessage: "Foto",
        updatedAt: timestamp,
        hasNewMessage: true,
      }),
    ]);
  }

  uploadImage(file, path, filename) {
    return this.afstorage.upload(`${path}`, file, {
      customMetadata: { ContentDisposition: "attachment", filename: filename },
    });
  }

  downloadImage(path) {
    return this.getStorageRef(path).getDownloadURL();
  }

  downloadFile(url) {
    var xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = function () {
      const ext = url.substr(url.lastIndexOf(".") + 1, 3);

      var a = document.createElement("a");
      a.href = window.URL.createObjectURL(xhr.response);

      var now = new Date();
      var hours = now.getHours();
      var minutes = now.getMinutes();
      var seconds = now.getSeconds();
      var hrs = hours < 10 ? `0${hours}` : `${hours}`;
      var mins = minutes < 10 ? `0${minutes}` : `${minutes}`;
      var secs = seconds < 10 ? `0${seconds}` : `${seconds}`;
      var strTime = `${hrs}h${mins}m${secs}s`;
      strTime =
        now.getFullYear() +
        "_" +
        (now.getMonth() + 1) +
        "_" +
        now.getDate() +
        "_" +
        strTime;

      a.download = `ALE_${strTime}.${ext}`;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
    };
    xhr.open("GET", url);
    xhr.send();
  }

  getImages(channelId: string, isNewOnly = false) {
    const query = new AFSQuery();
    query.limit = 50;
    if (!isNewOnly) {
      query.orderBy = ["createdAt", "asc"];
      return this.getImagesByChannel(channelId, query);
    }

    return this.lastTime.pipe(
      switchMap((datetime) => {
        query.where = [["createdAt", ">", datetime]];
        return this.getImagesByChannel(channelId, query);
      })
    );
  }

  getImagesOffline(channelId: string) {
    const query = new AFSQuery();
    query.where = [["visualized", "==", false]];
    return this.getImagesByChannel(channelId, query);
  }

  private getImagesByChannel(channelId: string, query: AFSQuery) {
    return this.collection<Message>(`chats/${channelId}/images`, query);
  }

  //método para buscar todos os chats do usuario logado de acordo com seu id
  getChatsUser(userId: string) {
    const query = new AFSQuery();
    query.where = [[`membersId`, "array-contains", userId]];
    return this.collectionPromise("chats", query);
  }

  OgetChannelsByUserId(start, end, userId) {
    return combineLatest(
      this.OgetChatsByCnpj(start, end, userId),
      this.OgetChatsByRazaoSocial(start, end, userId),
      this.OgetChatsByName(start, end, userId)
    ).pipe(
      map((arr) => {
        return _.uniqBy(
          arr.reduce((acc, cur) => acc.concat(cur)),
          "id"
        );
      }),
      map((channels) =>
        channels.map((c: any) => {
          c.memberId = Object.keys(c.members).find((key) => key != userId);
          return c;
        })
      )
    );
  }

  getChatsByFilters(start, end, userId) {
    return combineLatest(
      this.getChatsByCnpj(start, end, userId),
      this.getChatsByRazaoSocial(start, end, userId)
    ).pipe(map((arr) => arr.reduce((acc, cur) => acc.concat(cur))));
  }

  OgetChatsByName(start, end, userId) {
    const query = new AFSQuery();
    //query.startAt = start
    //query.endAt = end
    //query.orderBy = ['', 'asc']
    query.where = [["membersName", "array-contains", "José Ribeiro da Silva"]];

    return this.collection("users", query);
  }

  OgetChatsByCnpj(start, end, userId) {
    const query = new AFSQuery();
    query.startAt = start;
    query.endAt = end;
    query.orderBy = ["cnpj", "asc"];
    query.where = [["membersId", "array-contains", userId]];
    return this.collection("chats", query);
  }

  OgetChatsByRazaoSocial(start, end, userId) {
    const query = new AFSQuery();
    query.startAt = _.lowerCase(start);
    query.endAt = _.lowerCase(end);
    query.orderBy = ["razaoSocial", "asc"];
    query.where = [["membersId", "array-contains", userId]];
    return this.collection("chats", query);
  }

  getChatsByCnpj(start, end, userId) {
    const query = new AFSQuery();
    query.startAt = start;
    query.endAt = end;
    query.orderBy = ["cnpj", "asc"];
    query.where = [["membersId", "array-contains", userId]];
    return this.collectionPromise("chats", query);
  }

  getChatsByRazaoSocial(start, end, userId) {
    const query = new AFSQuery();
    query.startAt = _.lowerCase(start);
    query.endAt = _.lowerCase(end);
    query.orderBy = ["razaoSocial", "asc"];
    query.where = [["membersId", "array-contains", userId]];
    return this.collectionPromise("chats", query);
  }

  createDirectChannelByAssessor(owner: any, posto: any, user: any) {
    const query = new AFSQuery();
    query.where = [
      [`members.${owner.id}.state`, "==", true],
      [`members.${user.id}.state`, "==", true],
      [`cnpj`, "==", posto.cnpj],
    ];
    return new Promise((resolve) => {
      this.collectionPromise<Chat>("chats", query).then((channels) => {
        if (channels.length) {
          resolve(channels[0].id);
        } else {
          const members = {};
          const membersId = [];
          const membersName = [];
          const userOwner = {
            state: true,
            name: owner.displayName,
            role: owner.authenticator.userRole,
          };
          const userMember = {
            state: true,
            name: user.displayName,
            role: user.authenticator.userRole,
          };

          members[owner.id] = userOwner;
          members[user.id] = userMember;

          membersId.push(owner.id);
          membersId.push(user.id);

          membersName.push(_.lowerCase(owner.displayName));
          membersName.push(_.lowerCase(user.displayName));

          const newId = this.generatedId;
          const timestamp = this.timestamp;

          this.set("chats", newId, {
            cnpj: posto.cnpj,
            razaoSocial: _.lowerCase(posto.razao),
            segment: _.lowerCase(posto.segment),
            id: newId,
            type: "direct",
            members: members,
            membersId: membersId,
            membersName: membersName,
            clerkRole: owner.authenticator.userRole,
            owner: owner.id,
            purpose: `created by ${owner.displayName}`,
            createdAt: timestamp,
            updatedAt: timestamp,
            chatClosed: false,
            hasNewMessage: false,
          }).then((_) => resolve(newId));
        }
      });
    });
  }

  getImagesGallery(channelId: string) {
    const query = new AFSQuery();
    return this.collection<Message>(`chats/${channelId}/images`, query);
  }
}

import { Device } from "./../../../models/notifications";
import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { NotificationService } from "../../../services/notification.service";
import * as XLSX from "xlsx";
import * as mensagens from "../../../utils/mensagens";
import * as assertions from "../../../utils/assertionConcern";
import * as dateTime from "../../../utils/date-time";

@Component({
  selector: "app-notificacao-xls",
  templateUrl: "./notificacao-xls.component.html",
  styleUrls: ["./notificacao-xls.component.css"],
})
export class NotificacaoXlsComponent implements OnInit {
  sheetData: any;
  notification = { type: "push" };
  lines = 0;
  @ViewChild("inputXls") inputXls: ElementRef;

  constructor(
    private notificationService: NotificationService,
    private _elementRef: ElementRef
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "../assets/js/misc.js";
    this._elementRef.nativeElement.appendChild(s);
  }

  async onClickSendNotification() {
    if (this.wasSheetUploadedCorrectly()) {
      mensagens
        .aConfirmacao(
          "Você tem realmente certeza que deseja enviar uma Notificação para esses usuários ?"
        )
        .then(async (result) => {
          if (assertions.assertArgumentTrue(result.value, "") == undefined) {
            let notifications = await this.buildNotifications();

            this.notificationService
              .createNotifications(notifications)
              .then((result) => {
                if (result) this.cleanFields();
              });
          } else {
            mensagens.aMessagem(
              "error",
              "Notificação cancelada.",
              "Sua requisição de envio de solicitação foi cancelada com sucesso !",
              ""
            );
          }
        });
    }
  }

  /**
   * Checks if the sheet file was uploaded correctly.
   */
  wasSheetUploadedCorrectly() {
    var errors = assertions.assertArgumentNotNull(
      this.sheetData,
      "Não foi realizado nenhum upload, ou planilha está sem registros."
    );

    if (errors !== undefined) {
      mensagens.aMessagem(
        "error",
        "Erro ao ler o xls",
        errors,
        "Certifique-se que está tudo correto"
      );
      return false;
    }
    return true;
  }

  onRadioChange(obj: any, event: any) {
    this.notification.type = event.target.value;
  }

  onFileChange(evt: any) {
    var target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1)
      throw new Error("Selecione apenas um documento");

    var reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      var bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {
        type: "binary",
        cellDates: true,
      });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      this.sheetData = <XLSX.SheetAOAOpts>(
        XLSX.utils.sheet_to_json(ws, { header: 1 })
      );
      this.lines = this.sheetData.length;

      this.verifySheetData(this.sheetData);
    };

    reader.readAsBinaryString(target.files[0]);
  }

  cleanFields() {
    this.inputXls.nativeElement.value = "";
    this.sheetData = [];
    this.notification = { type: this.notification.type };
    this.lines = 0;
  }

  /**
   * Verifies the sheet data.
   * If any error is found, a message is shown to the user.
   * @param sheetData the sheet data containing all the data rows.
   */
  verifySheetData(sheetData) {
    var errors = [];

    sheetData.forEach((row, index) => {
      index = index + 1;

      errors.push(
        assertions.assertArgumentLengthXls(
          this.rowCnpj(row),
          `Cnpj invalido na linha: ${index}\n`
        )
      );
      errors.push(
        assertions.assertArgumentLengthMax(
          this.rowTitle(row),
          45,
          `Na linha: ${index} o titulo deve conter no máximo 45 caracteres\n`
        )
      );
      errors.push(
        assertions.assertArgumentLengthMax(
          this.rowMessage(row),
          200,
          `Na linha: ${index} a mensagem deve conter no máximo 200 caracteres\n`
        )
      );

      errors.push(
        assertions.assertArgumentNotEmpty(
          this.rowCnpj(row),
          `Cnpj não pode ser vazio na linha: ${index}\n`
        )
      );
      errors.push(
        assertions.assertArgumentNotEmpty(
          this.rowTitle(row),
          `Na linha: ${index} o titulo não deve ser vazio\n`
        )
      );
      errors.push(
        assertions.assertArgumentNotEmpty(
          this.rowMessage(row),
          `Na linha: ${index} a mensagem não deve ser vazia\n`
        )
      );
    });

    errors = errors.filter((error) => error !== undefined);
    if (errors.length > 0) {
      this.cleanFields();
      mensagens.aCustonHTML(
        "error",
        "Formato Xls inválido",
        errors.reduce(
          (previousError, currentError) => `${previousError}<br>${currentError}`
        ),
        "Verifique sua planilha e corrija"
      );
    }
  }

  /**
   * Gets the CNPJ column for a given data row.
   * @param row - the data row to get the CNPJ from
   */
  rowCnpj(row) {
    return row[0];
  }

  /**
   * Gets the title column for a given data row.
   * @param row - the data row to get the title from
   */
  rowTitle(row) {
    return row[1];
  }

  /**
   * Gets the message column for a given data row.
   * @param row - the data row to get the message from
   */
  rowMessage(row) {
    return row[2];
  }

  /**
   * Gets the URL column for a given data row.
   * @param row - the data row to get the URL from
   */
  rowUrl(row) {
    return row.length >= 4 ? row[3] : "";
  }

  /**
   * Gets the Date column for a given data row.
   * @param row - the data row to get the date from
   */
  rowDate(row) {
    return row.length >= 5
      ? dateTime.convertDateToString(row[4])
      : dateTime.convertDateToString(new Date());
  }

  /**
   * Builds the notification object with a specific format.
   * @param type the notification type e.g.: Push
   * @param date the date and time for when the notification will be sent
   * @param target the target for the notification
   * @param notificationData the notification data contain the title, text and url
   */
  buildNotificationObject(type, date, cnpjs, title, text, url) {
    return {
      type,
      date,
      target: {
        cnpjs,
      },
      message: {
        title,
        text,
        url,
      },
    };
  }

  async buildNotifications() {
    let notifications = [];

    await this.sheetData.map(async (row) => {
      let notification = await this.buildNotification(
        row,
        this.notification.type
      );
      notifications.push(notification);
    });

    console.log(notifications);

    return { notifications };
  }

  /**
   * Builds the notification based on the row from the sheet object.
   * @param row the row containing the sheet information regarding the notification
   * @param notificationType the notification type e.g.: 'Push'
   */
  async buildNotification(row, notificationType) {
    try {
      var cnpjs = [row[0]];
      var title = row[1];
      var text = row[2];
      var url = row.length >= 4 ? row[3] : undefined;
      var date = row.length >= 5 ? row[4] : new Date();

      return this.buildNotificationObject(
        notificationType,
        date,
        cnpjs,
        title,
        text,
        url
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}

export const resizer = (file) => {
  return new Promise((resolve) => {
    var img = document.createElement("img");
    var reader = new FileReader();
    reader.onload = function (e: any) {
      img.src = e.target.result;
      img.onload = function () {
        var canvas = document.createElement("canvas");

        var maxW = 800;
        var maxH = 600;
        var iw = img.width;
        var ih = img.height;

        var scale = Math.min(maxW / iw, maxH / ih);
        var iwScaled = iw * scale;
        var ihScaled = ih * scale;
        canvas.width = iwScaled;
        canvas.height = ihScaled;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, iwScaled, ihScaled);

        var dataurl = canvas.toDataURL("image/png");

        var arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        var newFile = new File([u8arr], file.name, { type: mime });

        if (newFile.size < file.size) resolve(newFile);
        else {
          resolve(file);
        }
      };
    };
    reader.readAsDataURL(file);
  });
};

import { Pipe, PipeTransform } from "@angular/core";
import { format, differenceInDays } from "date-fns";
import * as pt from "date-fns/locale/pt";

@Pipe({ name: "groupByDate" })
export class GroupByPipe implements PipeTransform {
  transform(value: any, value2: any, index: any): string {
    let dif;

    if (index > 0) {
      dif = differenceInDays(
        new Date(format(value, "MMM D, YYYY")),
        new Date(format(value2, "MMM D, YYYY"))
      );
    } else {
      dif = 1;
    }

    return dif >= 1 ? format(value, "MMM D, YYYY") : "";
  }
  /* transform(collection: Message[], property: string = 'createdAt'): Array<any> {
        
        //prevents the application from breaking if the array of objects doesn't exist yet
        if(!collection) {
            return null;
        }

        const groupedCollection = _.groupBy(collection, function (item){
            var newDate = new Date(item.createdAt.seconds*1000);
            return newDate.toLocaleDateString(undefined, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            })
        });

        return Object.keys(groupedCollection).map(date => ({ date: date, messages: groupedCollection[date] }));
    } */
}

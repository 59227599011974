import { AdminMarketGuard } from "./auth/admin-market.guard";
import { SacAssessorGuard } from "./auth/sac-assessor.guard";
import { NotificacaoXlsComponent } from "./components/notificacao/notificacao-xls/notificacao-xls.component";
import { NotificacaoTextoComponent } from "./components/notificacao/notificacao-texto/notificacao-texto.component";

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { ChatComponent } from "./components/chat/chat.component";
import { BannerComponent } from "./components/banner/banner.component";
import { HistoricoComponent } from "./components/historico/historico.component";
import { LoginComponent } from "./components/login/login.component";
import { AuthGuard } from "./auth/auth.guard";
import { SiteLayoutComponent } from "./components/common/layouts/site-layout/site-layout.component";
import { EsqueciSenhaComponent } from "./components/esqueci-senha/esqueci-senha.component";
import { NovaSenhaComponent } from "./components/nova-senha/nova-senha.component";

const routes: Routes = [
  //Rotas para o site
  {
    path: "",
    component: SiteLayoutComponent,
    children: [
      {
        path: "",
        redirectTo: "/dashboard",
        pathMatch: "full",
        canActivate: [AuthGuard],
        canLoad: [AuthGuard],
      },
      {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [AuthGuard],
        canLoad: [AuthGuard],
      },
      {
        path: "chat",
        component: ChatComponent,
        canActivateChild: [SacAssessorGuard],
        children: [
          {
            path: "",
            component: ChatComponent,
            canActivate: [AuthGuard],
            canLoad: [AuthGuard],
          },
        ],
      },
      {
        path: "banner",
        component: BannerComponent,
        canActivateChild: [AdminMarketGuard],
        children: [
          {
            path: "",
            component: BannerComponent,
            canActivate: [AuthGuard],
            canLoad: [AuthGuard],
          },
        ],
      },
      {
        path: "notificacao-texto",
        component: NotificacaoTextoComponent,
        canActivateChild: [AdminMarketGuard],
        children: [
          {
            path: "",
            component: NotificacaoTextoComponent,
            canActivate: [AuthGuard],
            canLoad: [AuthGuard],
          },
        ],
      },
      {
        path: "notificacao-xls",
        component: NotificacaoXlsComponent,
        canActivateChild: [AdminMarketGuard],
        children: [
          {
            path: "",
            component: NotificacaoXlsComponent,
            canActivate: [AuthGuard],
            canLoad: [AuthGuard],
          },
        ],
      },
      {
        path: "historico",
        component: HistoricoComponent,
        canActivateChild: [AdminMarketGuard],
        children: [
          {
            path: "",
            component: HistoricoComponent,
            canActivate: [AuthGuard],
            canLoad: [AuthGuard],
          },
        ],
      },
    ],
  },
  //rotas para login = públicas
  { path: "", redirectTo: "/login", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "esqueci-senha", component: EsqueciSenhaComponent },
  { path: "nova-senha", component: NovaSenhaComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

export default interface Message {
  from?: string;
  createdAt?: any;
  updatedAt?: any;
  data?: string;
  set?: boolean;
  visualized?: boolean;
  channelId?: string;
  id?: string;
  type?: string;
}

export const MESSAGE_TEXT = "TEXT";
export const MESSAGE_IMAGE = "IMAGE";

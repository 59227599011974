import { Router } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { AuthService } from "./../../auth/auth.service";
import { Users } from "../../models/users";
import { Component, OnInit } from "@angular/core";
import * as message from "../../utils/mensagens";
import * as _ from "lodash";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  user: Users = new Users();

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {}

  fazerLogin() {
    message.aWaitMessage2("Autenticando !", "Por favor aguarde...");

    setTimeout(() => {
      this.authService
        .login(this.user)
        .then((res: any) => {
          if (res.usuarioValido) {
            if (res.redirectChangePassword) {
              message.aToastMenssage(
                "warning",
                "Usuario com senha temporária."
              );
              return this.router.navigate(["/nova-senha"]);
            }
            this.router.navigate(["/dashboard"]);
            message.aToastMenssage("success", "Autenticado com sucesso!");
          }
        })
        .catch((error) => {
          message.aMessagem(
            "error",
            "Error",
            `${error}`,
            "Entre em contato com o suporte."
          );
        });
    }, 2000);
  }
}

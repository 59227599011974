import { NgModule } from "@angular/core";
import { ApolloModule, APOLLO_OPTIONS } from "apollo-angular";
import { HttpLinkModule, HttpLink } from "apollo-angular-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { APIGRAPHQL } from "../environments/consts";
import { ApolloLink } from "apollo-link";
import { AuthService } from "./auth/auth.service";
const uri = ""; // <-- add the URL of the GraphQL server here

const authLink = setContext((_, { headers }) => {
  const v = {} as any;
  const { CPF, Token } = new AuthService(v, v, v).getUser();
  return {
    headers: {
      ...headers,
      ["x-app-token"]: Token || "",
      cpf: CPF || "",
      mobile: "true",
    },
  };
});

export function createApollo(httpLink: HttpLink) {
  return {
    link: ApolloLink.from([authLink, httpLink.create({ uri: APIGRAPHQL })]),
    cache: new InMemoryCache(),
  };
}

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}

import { Component, OnInit, ElementRef } from "@angular/core";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  cargo = "Admin";

  constructor(private _elementRef: ElementRef) {}

  ngOnInit() {}

  ngAfterViewInit() {
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "../assets/js/misc.js";
    this._elementRef.nativeElement.appendChild(s);
  }
}

import { OnInit } from "@angular/core";
import { AuthService } from "./auth/auth.service";
import { Component } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { UsuarioService } from "./services/usuario.service";
import { Subscription } from "rxjs";
import { AngularFirestore } from "angularfire2/firestore";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "stf-admin-web";
  Loginsubscriber: Subscription;
  constructor(private authService: AuthService) {}

  ngOnInit() {}
}

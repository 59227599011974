import { CanActivateChild } from "@angular/router";
import { AuthService } from "./auth.service";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import * as CONSTS from "../../environments/consts";

@Injectable()
export class AdminMarketGuard implements CanActivateChild {
  constructor(private authService: AuthService, private router: Router) {}

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.verificaMenu();
  }

  verificaMenu() {
    let user = this.authService.getUser();
    if (
      user.TipoUsuario === CONSTS.USER_ADMIN ||
      user.TipoUsuario === CONSTS.USER_MARKET
    ) {
      return true;
    }
    this.router.navigate(["/dashboard"]);
    return false;
  }
}

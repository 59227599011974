import { NgModule } from "@angular/core";

//Components
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ChatComponent } from "./chat/chat.component";
import { BannerComponent } from "./banner/banner.component";
import { HistoricoComponent } from "./historico/historico.component";
import { LoginComponent } from "./login/login.component";
import { NotificacaoTextoComponent } from "./notificacao/notificacao-texto/notificacao-texto.component";
import { NotificacaoXlsComponent } from "./notificacao/notificacao-xls/notificacao-xls.component";
import { ConversaComponent } from "./chat/conversa/conversa.component";
import { ContatoListComponent } from "./chat/contato-list/contato-list.component";
import { ChannelListComponent } from "./chat/channel-list/channel-list.component";
import { GroupDateChatComponent } from "./chat/group-date-chat/group-date-chat.component";
import { EsqueciSenhaComponent } from "./esqueci-senha/esqueci-senha.component";
import { NovaSenhaComponent } from "./nova-senha/nova-senha.component";
import { LoadingSpinnerComponent } from "../shared/components/loading-spinner/loading-spinner.component";

//Modules
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { Select2Module } from "ng2-select2";
import { ApolloModule } from "apollo-angular";
import { JwtModule } from "@auth0/angular-jwt";
import { NgxMaskModule } from "ngx-mask";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { PickerModule } from "@ctrl/ngx-emoji-mart";

//Pipes
import { FilterContatosPipe } from "./../pipes/filter-contatos.pipe";
import { TimeAgoPipe } from "./../pipes/time-ago.pipe";
import { FilterPipe } from "./../pipes/filter.pipe";
import { InputTextOnlyDirective } from "../pipes/Input-text-only.pipe";
import { SafeHtml } from "../pipes/sanitizing.pipe";
import { UserProfilePipe } from "../pipes/user-profile.pipe";
import { GroupByPipe } from "../pipes/group-by.pipe";

@NgModule({
  declarations: [
    DashboardComponent,
    ChatComponent,
    ConversaComponent,
    BannerComponent,
    HistoricoComponent,
    LoginComponent,
    NotificacaoTextoComponent,
    NotificacaoXlsComponent,
    InputTextOnlyDirective,
    SafeHtml,
    UserProfilePipe,
    FilterPipe,
    TimeAgoPipe,
    FilterContatosPipe,
    ContatoListComponent,
    ChannelListComponent,
    GroupByPipe,
    GroupDateChatComponent,
    EsqueciSenhaComponent,
    NovaSenhaComponent,
    LoadingSpinnerComponent,
  ],
  imports: [
    RouterModule,
    FormsModule,
    BrowserModule,
    Select2Module,
    ApolloModule,
    JwtModule,
    NgxMaskModule.forRoot(),
    AutocompleteLibModule,
    PickerModule,
  ],
  exports: [
    DashboardComponent,
    ChatComponent,
    ConversaComponent,
    BannerComponent,
    HistoricoComponent,
    LoginComponent,
    NotificacaoTextoComponent,
    NotificacaoXlsComponent,
    ContatoListComponent,
    ChannelListComponent,
    GroupDateChatComponent,
    EsqueciSenhaComponent,
    NovaSenhaComponent,
    LoadingSpinnerComponent,
  ],
})
export class ComponentsModule {}
